export const esLocale = {
    "input": {
        "observation": "Observación|Observaciones",
        "status": "Estado",
        "competency": "Competencia|Competencias",
        "objectCode": "Código del Objeto",
        "uniqueIdentifier": "Identificador Único {name}",
        "search": "Buscar",
        "showInCourses": "Mostrar en Cursos",
        "showInFormations": "Mostrar en Formaciones",
        "showInMidias": "Mostrar en Medios",
        "nickname": "Apodo",
        "document": "Documento | Documentos",
        "foreignDocument": "Documento Extranjero",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "confirmPassword": "Confirmar Contraseña",
        "phone": "Número de Teléfono",
        "gender": "Género | Géneros",
        "birthDate": "Fecha de Nacimiento",
        "preferredLanguage": "Idioma Preferido",
        "photoUpload": "Subida de Foto",
        "personType": "Tipo de Persona",
        "admissionDate": "Fecha de Admisión",
        "role": "Función",
        "directSupervisor": "Superior Inmediato",
        "superiorsInterests": "Intereses del Superior",
        "userInterests": "Intereses del Usuario",
        "smsNotifications": "Notificaciones por SMS",
        "pushNotifications": "Notificaciones Push",
        "public": "Público",
        private: "Privado",
        "dragAndDrop": "Arrastra y suelta el archivo aquí <br>o",
        "category": "Categoría | Categorías",
        "expirationDate": "Fecha de Expiración",
        "resume": "Currículum",
        "createdAt": "Fecha de Creación",
        "certificateStructure": "Estructura de Certificado",
        "notShowAgain": "No mostrar este ítem nuevamente",
        "copyTitle": "Copia - {title}",
        "paperSize": "Tamaño del Papel",
        "dpi": "DPI",
        "orientation": "Orientación"
    },
    "button": {
        "ok": "Ok",
        "close": "Cerrar",
        "save": "Guardar",
        cancel: "Cancelar {text}",
        remove: "Remover {text}",
        disable: "Desactivar {text}",
        enable: "Activar {text}",
        "viewTermsOfUse": "Ver Términos de Uso",
        "login": "Iniciar sesión",
        "back": "Volver",
        "send": "Enviar",
        "myLearning": "Mi Aprendizaje",
        "learnMore": "Aprender Más",
        "myFavorites": "Mis Favoritos",
        "continue": "Continuar",
        "subscribe": "Inscribirse",
        "read": "Leer | Leído",
        "expired": "Expirado",
        "fullscreen": "Alternar Pantalla Completa",
        "listen": "Escuchar",
        "watch": "Ver",
        "loadMore": "Cargar Más",
        "details": "Detalles",
        "seeAll": "Ver Todo",
        "seeAgain": "Ver Nuevamente",
        "listenAgain": "Escuchar Nuevamente",
        "start": "Iniciar",
        "view": "Visualizar",
        "classRoom": "Sala de Clase",
        "access": "Acceder",
        "download": "Descargar {text}",
        "darkMode": "Modo Oscuro",
        "lightMode": "Modo Claro",
        "export": "Exportar",
        "clear": "Limpiar {text}",
        "today": "Hoy",
        "yesterday": "Ayer",
        "thisWeek": "Esta Semana",
        "thisMonth": "Este Mes",
        "resetPassword": "Restablecer Contraseña",
        "delete": "Eliminar {text}",
        "markUnred": "Marcar como No Leído",
        "update": "Actualizar",
        "add": "Agregar {text}",
        "list": "Lista",
        "seeMore": "Ver Más",
        "confirm": "Confirmar",
        "help": "Ayuda",
        "clientMode": "Modo Cliente",
        "next": "Siguiente",
        "saveExit": "Guardar y Salir",
        "expand": "Expandir",
        "collapse": "Contraer",
        "hide": "Ocultar",
        "edit": "Editar",
        "automation": "Automatización",
        "import": "Importar",
        "changePermission": "Cambiar Permiso",
        "removePermission": "Remover Permiso",
        "changePassword": "Cambiar Contraseña",
        "changeEmail": "Cambiar Correo Electrónico",
        "validateFile": "Validar Archivo",
        "importData": "Importar Datos",
        "newImport": "Nueva Importación",
        "processing": "Procesando...",
        "associations": "Asociaciones",
        "changeImage": "Cambiar Imagen",
        "viewPreview": "Vista Previa",
        "addYoutubeLink": "Agregar enlace de YouTube",
        "insert": "Insertar",
        "new": "Nuevo {text} | Nueva {text}",
        "text": "Texto",
        "htmlCode": "Código HTML",
        "button": "Botón",
        "checkAll": "Marcar Todos",
        "uncheckAll": "Desmarcar Todos",
        "checkAllVisible": "Marcar Todos Visibles",
        "uncheckAllVisible": "Desmarcar Todos Visibles",
        "revert": "Revertir",
        "accept": "Aceptar",
        "tryAgain": "Intentar Nuevamente",
        "set": "Establecer",
        "exit": "Salir",
        "applyFilters": "Aplicar Filtros",
        "resubscribe": "Reinscribirse",
        "approve": "Aprobar",
        "deny": "Denegar",
        "generateCertificate": "Generar certificado",
        "currentItem": "Ir a la pregunta actual",
        "nextQuestion": "Siguiente Pregunta",
        "submitAnswer": "Enviar Respuesta",
        "finish": "Finalizar",
        "startTest": "Iniciar evaluación",
        "continueTest": "Continuar evaluación",
        "notAnswer": "No responder",
        "returnLogin": "Regresar al Inicio de Sesión",
        "enterLogin": "Entrar",
        "goClassRoom": "Ir a la Sala de Clase",
        "editUserEmail": "Editar correo electrónico del usuario",
        "studentInscriptions": "Inscripciones de los estudiantes",
        "createQrCode": "Crear código QR para este objeto",
        "automateJourneys": "Automatizar jornadas",
        "reviewRequest": "Revisar solicitud",
        "print": "Imprimir {text}",
        "firstPage": "Primera página {text}",
        "press": "Presionar {text}",
        "nextPage": "Página siguiente {text}",
        "lastPage": "Última página {text}",
        "unselectSelected": "Desmarcar seleccionados",
        "removeSelected": "Eliminar seleccionados",
        "clone": "Clonar",
        "executeFlow": "Ejecutar el flujo de notificación manualmente",
        "accessLink": "Acceder al Enlace",
        "exitContract": "Salir del Contrato",
        "listing": "Listado",
        "nextContent": "Próximo Contenido",
        "previousContent": "Contenido Anterior",
        "leaveClassroom": "Salir del Aula",
        "addQuestions": "Agregar Preguntas",
        "backListAssessment": "Volver a la Lista de Evaluaciones de Conocimiento",
        "detailsAssessment": "Ver Detalles de la Evaluación",
        "finishAssessment": "Finalizar Evaluación",
        "continueAssessment": "Continuar Evaluación",
        "endAssessment": "Cerrar Evaluación",
        "goToLoginSettings": "Ir a la configuración de inicio de sesión"
    },
    "labels": {
        row: "Fila|Filas",
        "denied": "Denegado",
        "document": "Documento | Documentos",
        "course": "Curso | Cursos",
        "trail": "Ruta | Rutas",
        "link": "Enlace | Enlaces",
        "visitLink": "Visitar enlace",
        "video": "Video | Videos",
        "podcast": "Podcast | Podcasts",
        "scorm": "Scorm | Scorms",
        "pdf": "PDF | PDFs",
        "image": "Imagen | Imágenes",
        "photo": "Foto|Fotos",
        value: "Valor|Valores",
        "of": "de",
        "items": "Items",
        "showing": "Exhibiendo",
        "progress": "Progreso",
        "all": "Todos",
        "media": "Medio",
        "actions": "Acciones | Acción",
        "notStarted": "No iniciado",
        "complete": "Completo",
        "completed": "Completado",
        "listPerPage": "Lista por página",
        "selected": "Seleccionado",
        "pagination": "Mostrando {min} - {max} de {total}",
        "currentPage": "{current} de {total} páginas",
        "fullname": "Nombre Completo",
        "calledBy": "Prefiero ser llamado por",
        "preferLanguage": "Mi idioma preferido",
        "skill": "Competencia | Competencias",
        "supervisor": "Supervisor Directo",
        "educationLevel": "Nivel de Educación",
        "unit": "Unidad | Unidades",
        "department": "Departamento | Departamentos",
        "jobTitle": "Puesto | Puestos",
        "pending": "Pendiente",
        "approved": "Aprobado",
        "failed": "Fallido",
        "onGoing": "En curso",
        "total": "Total",
        "content": "Contenido | Contenidos",
        "user": "Usuario | Usuarios",
        "active": "Activo|Activos",
        "inactive": "Inactivo|Inactivos",
        "visible": "Visible|Visibles",
        "hidden": "Oculto|Ocultos",
        "yes": "Sí",
        "no": "No",
        "filter": "Filtro | Filtros",
        "cnpj": "CNPJ",
        "cpf": "CPF",
        "naturalPerson": "Natural",
        "legalEntity": "Jurídica",
        "foreign": "Extranjero",
        "companyName": "Nombre de la Empresa",
        "landlineNumber": "Número Fijo",
        "mobilePhone": "Teléfono Móvil",
        "fantasyName": "Nombre de Fantasía",
        "nameOrCompany": "Nombre o Nombre de la Empresa",
        "district": "Barrio",
        "state": "Estado",
        "city": "Ciudad",
        "country": "País",
        "homeNumber": "Número Residencial",
        "address": "Dirección",
        "adjunct": "Complemento",
        "customerActivity": "Actividad del Cliente",
        "zipCode": "Código Postal",
        "number": "Número",
        "company": "Empresa",
        "aliareCode": "Código Aliare",
        "date": "Fecha",
        "projectStatus": "Estado del Proyecto",
        "aliareProducts": "Productos Aliare",
        "numContractedUsers": "Límite de usuarios {0}",
        "contractedDiskSpace": "Límite de uso de disco {0}",
        "communicationEmail": "Correo electrónico de comunicación usado por la empresa",
        "embedded": "Embebido",
        "partner": "Socio",
        "adhesionAgreement": "Término de Adhesión",
        "experimentation": "Experimentación",
        "didactica": "Didáctica",
        "distributor": "Distribuidor",
        "inProduction": "En Producción",
        "blocked": "Bloqueado",
        "canceled": "Cancelado",
        "code": "Código",
        "ordering": "Ordenación",
        "asc": "Ascendente",
        "desc": "Descendente",
        "display": "Visualización",
        "settings": "Configuraciones",
        "forceUpdatePermission": "Forzar Actualización de Permiso",
        "noPermission": "Sin Permiso",
        "permission": "Permiso | Permisos",
        "clickHere": "Haga click aquí",
        "and": "y",
        "selectFile": "Seleccionar archivo",
        "audio": "Audio|Audios",
        "file": "Archivo",
        "objectCode": "Código del {type}",
        "cardImage": "Imagen de la Tarjeta",
        "summary": "Resumen",
        "fileName": "Nombre del Archivo",
        "fileSize": "Tamaño del Archivo",
        "scormType": "Tipo Scorm",
        "object": "Objeto | Objetos",
        "diagnostic": "Diagnóstico",
        "reaction": "Reacción",
        "learning": "Aprendiendo",
        "applicability": "Aplicabilidad",
        "result": "Resultado",
        assessmentType: "Tipo de Evaluación",
        "order": "Orden",
        "statement": "Declaración",
        "questionType": "Tipo de Pregunta",
        requiredScore: "Puntuación Mínima (%)",
        "newAttempt": "¿Permitir Nuevo Intento?",
        "numAttempt": "Número de Intentos",
        "automatic": "Automático",
        "showTimer": "Agregar Temporizador en la Evaluación",
        "test": "Evaluación | Evaluaciones",
        assessment: "Evaluación|Evaluaciones",
        "never": "Nunca",
        "always": "Siempre",
        "whenReject": "Cuando desaprobado",
        "objective": "Objetiva",
        "multipleChoice": "Selección Múltiple",
        "trueOrFalse": "Verdadero / Falso",
        "scale": "Escala",
        "essay": "Disertativa",
        "typeQuestions": "Elija el tipo de pregunta",
        "attachImage": "Adjuntar Imagen",
        alternative: "Alternativa|Alternativas",
        "customFeedback": "¿Feedback Personalizado?",
        "feedbackCorrect": "Feedback Cuando Correcto",
        "feedbackIncorrect": "Feedback Cuando Incorrecto",
        "feedbackResponding": "Feedback Cuando Respondiendo a la Pregunta",
        "question": "Pregunta | Preguntas",
        "synopsis": "Sinopsis",
        "mandatorySequence": "Secuencia Obligatoria",
        "daysLimit": "Límite de días para conclusión",
        "mandatoryCompletion": "Conclusión Obligatoria",
        "challenge": "Desafío | Desafíos",
        "termOfUse": "Término de Uso|Términos de Uso",
        "termOfAgreement": "Término de Acuerdo|Términos de Acuerdo",
        "section": "Sección | Secciones",
        "learningObjects": "Objeto de Aprendizaje",
        "step": "Paso | Pasos",
        "sortSections": "Ordenar Secciones",
        "associate": "Vincular {title}",
        "communication": "Comunicación | Comunicaciones",
        "insertLink": "Insertar un enlace",
        "use": "Usar {field}",
        "segmentation": "Segmentación | Segmentaciones",
        "publicationPeriod": "Periodo de Publicación",
        "certificate": "Certificado | Certificados",
        "featuredCommunications": "Comunicaciones Destacadas",
        "post": "Publicación",
        "displayCommunication": "¿Mostrar siempre comunicaciones destacadas?",
        "event": "Evento",
        "interval": "Intervalo",
        "daysSubmission": "Días para Envío",
        "daysNoAccess": "Días sin Acceso a la Plataforma",
        "additionalRecipient": "Destinatario Adicional",
        "subject": "Asunto",
        "draft": "Borrador",
        "published": "Publicado",
        "copySuperior": "¿Copiar Superior Inmediato en el correo?",
        "copy": "Copiar",
        "noCopy": "No copiar",
        "notificationEvent": "¿Cuál es el evento de notificación?",
        "sendingInterval": "Intervalo de Envío de Correo Electrónico",
        "days": "Días",
        "provideEmail": "Proporcione un correo electrónico para prueba",
        "templateLayout": "Diseño de la Plantilla",
        "templatePreview": "Vista Previa de la Plantilla",
        "greetings": "Hola <strong>{name}</strong>, ¿Cómo estás?",
        "undefined": "Indefinido",
        "achievements": "Logros",
        "page": "Página | Páginas",
        "classification": "Clasificación",
        "typeContent": "Tipo de Contenido",
        "daysCompleted": "Días para Completar",
        "rule": "Regla | Reglas",
        "applicationPeriod": "Período de Aplicación",
        "to": "Hasta",
        "completeWithin": "Completar en hasta",
        "score": "Puntuación",
        "conquest": "Conquista",
        "useMedia": "Utilizada en medios",
        "useCourses": "Utilizada en cursos",
        "useTrails": "Utilizada en rutas",
        "useTests": "Utilizada en evaluaciones",
        "repository": "Repositorio | Repositorios",
        "operator": "Operador",
        "or": "O",
        "male": "Masculino",
        "female": "Femenino",
        "nonBinary": "No Binario",
        "debug": "Debug",
        "showGuidelines": "Mostrar líneas guía",
        "background": "Fondo",
        "changeBackground": "Cambiar imagen de fondo",
        "removeBackground": "Remover imagen de fondo",
        "flipHorizontally": "Invertir horizontalmente",
        "flipVertically": "Invertir verticalmente",
        "element": "Elemento | Elementos",
        "addText": "Agregar Texto",
        "addImage": "Agregar Imagen",
        "simulateValues": "Simular valores",
        "preview": "Vista Previa {text}",
        "tag": "Etiqueta | Etiquetas",
        "certificateId": "ID del Certificado",
        option: "Opción|Opciones",
        optional: "Opcional|Opcionales",
        "moreOptions": "Más Opciones",
        "opacity": "Opacidad | Opacidades",
        "width": "Ancho",
        "height": "Alto",
        "align": "Alineación | Alineaciones",
        "layer": "Capa | Capas",
        "formatting": "Formateo",
        "textColor": "Color del Texto",
        "font": "Fuente",
        "textSize": "Tamaño del Texto",
        "italic": "Itálico",
        "bold": "Negrita",
        "mine": "Mío",
        "lightTheme": "Tema Claro",
        "darkTheme": "Tema Oscuro",
        "pageIcon": "Ícono de la Página",
        "corporativeEducation": "Educación Corporativa",
        "interface": "Interfaz",
        "primaryColor": "Color Primario",
        "fontColor": "Color de la Fuente",
        "autoFontColor": "Color de la Fuente Automático?",
        "secondColor": "Segundo Color",
        color: "Color|Colores",
        backgroundColor: "Color de Fondo",
        pick: "Seleccionar",
        left: "Izquierda",
        right: "Derecha",
        syllabus: "Plan de Estudios",
        backOfCertificate: "Verso del Certificado",
        "dateTime": "Fecha & Hora",
        "username": "Nombre de Usuario",
        "revertChanges": "Revertir Cambios",
        "menu": "Menú",
        "for": "para",
        "character": "Carácter | Caracteres",
        "example": "Ejemplo",
        "privacyPolicy": "Política de Privacidad",
        "hi": "Hola",
        "default": "Predeterminado",
        "homePage": "Página de Inicio",
        "previousPage": "Página Anterior {text}",
        "table": "Tabla",
        "notRegistered": "No inscriptos",
        "both": "Ambos",
        "automatedNotification": "Notificación automatizada",
        "externalCertificate": "Certificado Externo",
        "person": "Persona",
        "started": "Iniciado",
        "transfer": "Transferencia",
        "autoPlay": "Reproducción automática",
        "nextActivity": "Próxima actividad en",
        "extension": "Extensión",
        "vocational": "Profesional",
        "academic": "Académico",
        "specialization": "Especialización",
        "describeOthers": "Otros (describa)",
        "other": "Otro | Otros",
        "copied": "Copiado",
        "copyLink": "Copiar enlace",
        "dissociate": "Desvincular",
        "reject": "Rechazar",
        "newEmail": "Nuevo Correo Electrónico",
        "confirmEmail": "Confirmar el correo electrónico",
        "registered": "Inscriptos",
        "unnamed": "Sin nombre",
        "noUnit": "Sin unidad",
        "setDate": "Definir fecha",
        "selectAllPage": "Seleccionar todos de la página",
        "unmarkAllPage": "Desmarcar todos de la página",
        "invertSelection": "Invertir selección de la página",
        "tablePage": "{0}/Página",
        "linkLinkedin": "Vincular a LinkedIn",
        "shareLinkedin": "Compartir en LinkedIn",
        "myCertificate": "Mi certificado",
        "externalLink": "Enlace externo",
        "remaining": "Restante",
        "attempts": "Intentos",
        "minimumGrade": "Nota mínima",
        "timeSpent": "Tiempo gastado",
        "hit": "Acierto | Aciertos",
        "miss": "Error | Errores",
        obtainedScore: 'Nota Obtenida',
        "viewAnswer": "Ver Respuesta",
        "attemptsExhausted": "Intentos agotados",
        "myAnswers": "Mi respuesta | Mis respuestas",
        "correctAnswers": "Respuestas correctas",
        "theMedia": "El {object} | El {object}",
        "studyPlan": "Plan de Estudio",
        "contract": "Contrato | Contratos",
        "field": "Campo",
        "fieldName": "Nombre del Campo",
        "data": "Datos",
        "congratulations": "Felicidades",
        "goTo": "Ir a {0}",
        "conclusion": "Conclusión",
        "educationalInstitution": "Institución educativa",
        "typeCourse": "Tipo de curso",
        "import": "Importación de {type}",
        "incorrect": "Incorrect | Incorrects",
        "correct": "Correcto | Correctos",
        "answerList": "Lista de respuestas",
        "note": "Nota | Notas | Anotación | Anotaciones",
        "notification": "Notificación | Notificaciones",
        "notImplemented": "No implementado",
        "advanced": "Avanzado",
        "publicPanel": "Panel Público",
        "replicate": "Replicar Registros del Distribuidor",
        "panel": "Panel | Paneles",
        "logo": "Logotipo | Logotipos",
        "banner": "Banner | Banners",
        "pageTitleAndPageDescription": "Título de la Página y Descripción de la Página",
        "loginImage": "Imagen de Inicio de Sesión",
        "loginBoxPosition": "Posición del Cuadro de Inicio de Sesión",
        "logoutAddress": "Dirección de Cierre de Sesión",
        "privacyPolicyAddress": "Dirección de la Política de Privacidad",
        "standardTermsOfUse": "Términos de Uso Estándar",
        "certificates": "Certificados",
        "standardCertificate": "Certificado Estándar",
        "remove": "Remover",
        "correctOrIncorrect": "Correcta o Incorrecta",
        "trueOrFalses": "Verdadero o Falso",
        "unitCode": "Código: {title}",
        "pt": "Portugués",
        "en": "Inglés",
        "es": "Español",
        "expirationDate": "Expira el: {date}",
        "reactions": "{count} Reacción | {count} Reacciones",
        "react": "Reaccionar",
        "reacted": "Reaccionado",
        "dateExpiration": "Fecha Venc.",
        "inscriptionDate": "Fecha de Inscripción",
        "practice": "Práctica",
        "behavior": "Comportamiento",
        "linkManager": "Vincular Gerente Evaluador",
        "evaluatorManager": "Gerente Evaluador",
        "linkTargeting": "Vincular Segmentación",
        "microphone": "Micrófono",
        "screen": "Pantalla",
        "camera": "Cámara",
        "uploadType": "Tipo de Carga",
        "typeOfAnswer": "Tipo de Respuesta",
        maxScore: "Nota Máxima",
        "courseOrTrail": "Curso/Trayectoria",
        "availableIn": "Disponible en",
        "heldIn": "Realizado en",
        "ratedIn": "Evaluado en",
        "disapproved": "Reprobado",
        "newTry": "Nuevo Intento",
        "behaviorAssessment": "Evaluaciones de Comportamiento | Evaluación de Comportamiento",
        reviewASsessment: "Revisar Evaluación",
        reviewing: "Revisando: {title}",
        "closingAssessment": "Cerrando Evaluación",
        "timeUsed": "Tiempo Utilizado",
        "partialGrade": "Nota Parcial",
        "landscape": "Horizontal",
        "portrait": "Vertical",
        carousel: "Carrusel|Carruseles",
        slide: "Diapositiva|Diapositivas",
        "answersCorrect": "Respuesta Correcta",
        "answersIncorrect": "Respuesta Incorrecta",
        "answersPartiallyCorrect": "Respuesta Parcialmente Correcta"
    },
    "placeholder": {
        "select": "Seleccionar {field}",
        "email": "Ingrese su correo electrónico",
        "password": "Ingrese su contraseña",
        "newPassword": "Ingrese su nueva contraseña",
        "confirmPassword": "Confirme su contraseña",
        "uniqueIdentifier": "Ingrese el identificador único {name}",
        "learnToday": "¿Qué aprenderemos hoy?",
        "search": "Buscar {field}",
        "quickSearch": "Búsqueda rápida",
        "searchFuncionality": "¿Qué funcionalidad desea buscar?",
        "help": "Ayuda",
        "notification": "Notificación | Notificaciones",
        "fullname": "Ingrese su Nombre Completo",
        "zipCode": "Ingrese su Código Postal",
        "address": "Ingrese su Dirección",
        "enter": "Ingrese {field}",
        "sectionsAssociate": "Asociar secciones",
        "communicationsAssociate": "Asociar Comunicaciones",
        "clickToAssociate": "Haga click para asociar {field}",
        "score": "Ingrese la puntuación entre {0} y {1}",
        "statement": "Ingrese la declaración de la pregunta que se presentará al alumno...",
        "feedbackCorrect": "Ingrese el feedback que se presentará al alumno cuando responda correctamente a la pregunta...",
        "feedbackIncorrect": "Ingrese el feedback que se presentará al alumno cuando responda incorrectamente a la pregunta...",
        "feedbackResponding": "Ingrese el feedback que se presentará al alumno cuando responda a la pregunta...",
        "postContent": "Contenido de la publicación",
        "selectMultiple": "Seleccione uno o más {field}",
        "uploadImage": "Envíe su imagen",
        "subject": "Proporcione un asunto para el correo electrónico",
        "enterPasteScript": "Inserte/pegue aquí el script adicional",
        "enterPasteScriptContent": "Inserte/pegue el contenido del script",
        "enterPasteScriptName": "Inserte el nombre/identificador del script (opcional)",
        "enterPasteAdress": "Inserte/pegue la dirección del script (opcional)",
        "insertLink": "Inserte la dirección de redireccionamiento del banner. Ejemplo: https://www.susitio.com",
        "confirm": "Confirme {field}",
        "reason": "Por favor, indique el motivo de la eliminación",
        "answerHere": "Escriba su respuesta aquí",
        "enterNickname": "Introduzca el apodo",
        "enterDocument": "Introduzca el documento",
        "enterTitle": "Introduzca el título",
        "enterId": "Introduzca el ID",
        "enterDescription": "Introduzca la descripción",
        "enterObservation": "Introduzca las observaciones",
        "enterName": "Introduzca el nombre",
        "enterEmail": "Introduzca el correo electrónico",
        "enterContent": "Introduzca el contenido",
        "enterFantasyName": "Introduzca el nombre comercial",
        "enterAddress2": "Introduzca el complemento de dirección",
        "enterDistrict": "Introduzca el barrio",
        enterClassification: "Introduzca la clasificación",
        "enterCpf": "Introduzca el CPF",
        "enterCnpj": "Introduzca el CNPJ",
        "enterCpfCnpj": "Introduzca el CPF/CNPJ",
        "enterForeignDocument": "Introduzca el documento extranjero",
        "enterFullName": "Introduzca el nombre completo",
        "enterCompanyName": "Introduzca el nombre de la empresa",
        "enterAlternative": "Introduzca la alternativa {title}",
        "enterAdditionalRecipient": "Introduzca el destinatario adicional",
        "enterNumberOfDays": "Introduzca el número de días",
        "enterNumberOfUsers": "Introduzca el número de usuarios",
        "enterDiskSpace": "Introduzca el espacio en disco (GBs)",
        "enterCourseLimit": "Introduzca el límite de cursos",
        "enterTrailsLimit": "Introduzca el límite de trayectos",
        "enterDefaultUrl": "Introduzca la URL predeterminada",
        "enterCustomUrl": "Introduzca la URL personalizada",
        "enterSynopsis": "Introduzca la sinopsis",
        "enterCode": "Introduzca el código",
        "enterSummary": "Introduzca el resumen",
        "enterObjectCode": "Introduzca el código del objeto",
        "enterInstitution": "Introduzca la institución",
        "enterLinkBanner": "Ingrese la dirección web a la que se redirigirá el banner",
        "numberDays": "N.º de Días",
        "contentSearch": "Búsqueda de contenido",
        "suggestions": "Sugiera un nuevo medio, trayectoria, curso o tema que le gustaría ver en la plataforma.",
        "noResults": "¿No encontró lo que buscaba? ¡Envíe sus sugerencias de contenido para ayudarnos a mejorar su experiencia en la plataforma!"
    },
    "tooltips": {
        "toggleOrder": "Alternar Orden",
        "unreadNotifications": "Notificaciones no leídas",
        "associateMedias": "Asociar videos, audios, imágenes, PDFs, Scorm, etc.",
        "associateCourses": "Asociar cursos en la etapa",
        "associateTests": "Asociar pruebas en la etapa",
        "synopsis": "Proporcione una breve descripción, dejando en claro lo que se abordará en {type} o las partes más importantes.",
        "detailsDescription": "Aquí debe proporcionar más detalles sobre los objetivos de {type} para que el alumno pueda visualizar claramente lo que se cubrirá.",
        "profile": {
            "calledBy": "Al completar este campo, la plataforma lo utilizará en toda la comunicación con usted",
            "developSkills": "¡Basado en sus intereses seleccionados, sugeriremos contenido relevante para que impulse su conocimiento y su carrera!",
            "personalData": "Los campos de Correo electrónico y Documento solo pueden ser modificados por la administración de la plataforma o por RRHH (cuando esté integrado).",
            "corporateData": "Los datos de esta sección son modificados por el sistema de RRHH (cuando esté integrado) o el gerente de cuenta e inscripciones.",
            "academicData": "Ingrese aquí los datos relacionados con su recorrido académico y otros."
        },
        "originFilter": "Para visualizar o filtrar contenido de distribuidores, seleccione la opción 'Distribuidor' en la columna 'Origen'.",
        "export": "Habilitado, exportará todos los registros disponibles. Deshabilitado, exportará solo los registros visibles en la tabla.",
        "contracts": {
            "aliareCode": "Este código se carga automáticamente cuando se integra a la Plataforma Aliare.",
            "observation": "Ingrese aquí los detalles específicos del contrato necesarios para documentar negociaciones u otras actividades.",
            "sectionField": "En este campo, puede vincular una o más secciones creadas por Plantar y compartirlas con el contrato, haciéndolas visibles en el aprendizaje del cliente.",
            "communicationsField": "En este campo, puedes vincular una o más comunicaciones creadas por Plantar y compartirlas con el contrato, haciéndolas visibles en el aprendizaje del cliente."
        },
        "units": {
            "activeAutomation": "Automatización Activa",
            "childUnit": "Unidades Hijas"
        },
        "departments": {
            "observation": "Puede proporcionar un texto aquí que ayude a los usuarios sobre el departamento."
        },
        "jobsTitles": {
            "developSkills": "Al proporcionar competencias, la plataforma sugerirá contenido que ayuda al usuario a adquirir conocimientos relacionados con ellas."
        },
        "users": {
            "supervisor": "Especifique el líder, gerente o superior inmediato del usuario.",
            "unit": "Especifique la unidad de negocio a la que está vinculado el usuario.",
            "permission": "Los permisos definen las rutinas a las que el usuario puede acceder. En la lista, haga click en el menú Acciones (Definir permiso)",
            "receiveEmail": "Habilitado, define que los usuarios recibirán los correos electrónicos de sus inscripciones.",
            classification: 'Clasificación que determina el panel de dashboard: Alumno, Administrador o Gestor'
        },
        "repository": {
            "summary": "Proporcione un breve contexto sobre el contenido. El texto se presentará al alumno en la creación de cursos, rutas y lugares de consumo.",
            "developSkills": "¿Qué tal vincular competencias que este objeto apoyará a los alumnos? Puede seleccionar una o más competencias, y la plataforma (si está configurada) puede sugerir contenido para alumnos o posiciones basándose en los objetivos de aprendizaje del alumno y/o gerente.",
            "duration": "Esta es la duración que encontramos en su archivo de medios y se mostrará al usuario durante la reproducción. Si lo desea, puede editarlo ajustando el tiempo.",
            "disableActions": "Esta acción no se puede realizar con un medio compartido.",
            "qrcode": "Para leer este código, puede usar una aplicación lectora de QR o algunas aplicaciones de cámara en su teléfono inteligente.",
            "downloadQrcode": "Descargar el QR Code",
            "shareQrcode": "Compartir QR Code"
        },
        "tests": {
            "diagnostic": "Tiene como objetivo recopilar información sobre el conocimiento previo, habilidades y competencias de los alumnos.",
            "reaction": "Tiene como objetivo medir el nivel de satisfacción de los alumnos con la capacitación y su percepción general del proceso.",
            "learning": "Tiene como objetivo evaluar el conocimiento adquirido a partir de la capacitación aplicada.",
            "applicability": "Tiene como objetivo evaluar si el alumno aplicó lo aprendido en el proceso de capacitación.",
            "result": "Tiene como objetivo evaluar los resultados obtenidos a partir de la capacitación aplicada.",
            "testType": "Basado en el tipo elegido, se presentarán modelos de pregunta y respuesta.",
            "developSkills": "Al proporcionar competencias, la plataforma puede sugerir pruebas que ayudan al usuario a adquirir conocimientos relacionados con ellas.",
            requiredScore: "Proporcione el porcentaje mínimo de respuestas correctas para que el alumno apruebe el test.",
            "newAttempt": "Especifique si el usuario puede tener nuevos intentos para completar la prueba y las reglas que se aplicarán.",
            "numAttempt": "Especifique cuántos intentos tendrá el usuario para completar la prueba.",
            "maxQuestions": "Especifique el número máximo de preguntas que se presentarán al usuario. Si hay más que el número especificado, se cargarán en intentos posteriores, si las hay.",
            "displayAnswers": "Si se marca, muestra las respuestas correctas e incorrectas al usuario después de confirmar la opción seleccionada.",
            "shuffleQuestions": "Permite mezclar las preguntas en la prueba, saliendo del orden de inclusión.",
            "shuffleOptions": "Permite mezclar las opciones de la pregunta, saliendo del orden de inclusión.",
            "showTimer": "Si está activado, muestra el temporizador al usuario en la prueba.",
            "duration": "Especifique el tiempo que el usuario tiene para completar la evaluación, o deje en blanco para no tener límite de tiempo.",
            "typeQuestions": "Especifique el objetivo de su pregunta eligiendo entre las opciones a continuación.",
            "attachImage": "Permite vincular una imagen para presentarla al usuario durante la evaluación.",
            "scale": "Permite al alumno elegir un número en una escala de 1 al valor seleccionado a continuación.",
            "customFeedback": "Permite incluir feedback para presentarlo al usuario después de la corrección de la opción respondida.",
            "behavior": "La Evaluación de Comportamiento es una evaluación que se lleva a cabo después de la finalización del curso/trayectoria. El plazo de envío será definido por su gerente y se le notificará cuando esté disponible.",
            "submittingBehaviorAssessment": "Plazo para Enviar la Evaluación de Comportamiento"
        },
        "courses": {
            "sequence": "Habilitado, define que el usuario necesita seguir la secuencia de la estructura definida durante el consumo. Deshabilitado, el usuario puede navegar libremente por cualquier contenido.",
            "duration": "Proporcione la duración estimada en horas y minutos para el curso. Para calcular automáticamente los ítems insertados, simplemente haga click en el botón izquierdo (calculadora).",
            "progress": "Especifique el porcentaje de progreso necesario para la generación del certificado (cuando se asocia) y/o para alcanzar el estado de conclusión.",
            "daysLimit": "Plazo máximo (en días) para que un alumno complete el curso. Si se define como 0, no hay control de límite.",
            "mandatoryCompletion": "Define si el alumno necesita completar el contenido dentro del plazo de finalización.",
            "certificate": "El certificado puede vincularse a un curso. Una vez insertado aquí, se convierte en el estándar para el curso",
            "skills": "¿Qué tal vincular competencias que este objeto apoyará a los alumnos? Puede seleccionar una o más competencias, y la plataforma (si está configurada) puede sugerir contenido para alumnos o posiciones basándose en los objetivos de aprendizaje del alumno y/o gerente.",
            "challenges": "¿Qué tal vincular desafíos que este objeto apoyará a los alumnos? Puede seleccionar uno o más desafíos, y la plataforma (si está configurada) puede sugerir contenido para alumnos o posiciones basándose en los objetivos de aprendizaje del alumno y/o gerente.",
            "termOfUse": "Un término de uso puede ser específico para un curso y, cuando se asocia, se mostrará al final del contenido.",
            "sections": "Aquí puede vincular el curso directamente a la sección ya registrada. Al hacerlo, el contenido ya se presentará en el proceso de aprendizaje para el alumno."
        },
        "sections": {
            "typeSection": "Elija el tipo de su sección.",
            "environment": "¿A qué ambiente pertenece la sección?",
            "useSegmentation": "Elegir vincular una segmentación elimina enlaces anteriores con unidades, departamentos y cargos.",
            "useDepartment": "Al elegir vincular una unidad, departamento o cargo, las segmentaciones anteriores se desvincularán automáticamente.",
            "useBoth": "No es posible vincular segmentación y Departamento, Cargo y Unidad al mismo tiempo.",
            "period": "Especifique el período de disponibilidad para la sección o déjelo en blanco para mostrarla siempre.",
            "move": "Mover a {title}"
        },
        "challenges": {
            "completeWithin": "0 no tiene plazo de conclusión"
        },
        "categories": {
            "types": "Tipo que definirá en qué lugar se listará la categoría"
        },
        "segmentations": {
            "observation": "Ingrese sus observaciones aquí",
            "operator": "Aquí restringe el uso de la Segmentación para un contexto determinado. Elija 'O' para atender al usuario si está en al menos una de las reglas. Elija 'Y' para atender al usuario solo si se cumplen todas las reglas.",
            "selectValidate": "Es necesario tener al menos un vínculo en la Segmentación"
        },
        "certificates": {
            "tag": "Estas etiquetas se reemplazan automáticamente por los valores indicados",
            "linkedin": "Aquí crea una nueva publicación en Linkedin a partir de su certificado",
            "profile": "Aquí agrega un nuevo certificado a su perfil, incluyendo nuevas competencias adquiridas, etc.",
            "unableCertificate": "Todavía no ha alcanzado las condiciones para recibir el certificado",
            "emitCertificate": "Emitir certificado"
        },
        "permissions": {
            "select": "De acuerdo con el perfil deseado, marque en cada módulo las funcionalidades a las que los usuarios vinculados a este grupo de permisos podrán acceder.",
            "validateSelect": "¡Debe seleccionar al menos un permiso!"
        },
        "customizations": {
            "collapsedMenu": "La imagen se mostrará en el menú lateral colapsado de la plataforma",
            "menuLogin": "La imagen se mostrará en el menú lateral y en la página de inicio de sesión de la plataforma",
            "pageIcon": "El ícono de la página generalmente se muestra en la pestaña del navegador",
            "previewChanges": "Visualice los cambios en tiempo real en el sistema"
        },
        "automated": {
            "forUsers": "Habilitado, define que la automatización será válida para usuarios registrados a partir de la fecha informada en el campo siguiente. Obs: Si está marcado, el campo de fecha será obligatorio",
            "forAll": "Habilitado, define que la automatización será válida para todos los usuarios registrados, independientemente de la fecha de su registro.",
            "setDate": "Campo Fecha: Al informarla, se considerará para la inscripción solo los registros de usuarios mayores o iguales a la fecha informada",
            "notifyEmail": "Habilitado, define que los usuarios recibirán los correos electrónicos de sus inscripciones."
        },
        "details": {
            "limitDays": "Límite de días para conclusión",
            "requiredProgress": "Progreso requerido"
        },
        "termsOfUse": {
            "type": "El tipo <strong>Plataforma</strong> podrá vincularse a los parámetros de acceso para que el usuario acepte en el primer acceso o siempre que las reglas cambien, ya el tipo <strong>Aprendizaje</strong> se vinculará a un Curso o Ruta definiendo criterios o reglas de aceptación en relación al contenido.",
            "tags": "Para insertar una etiqueta en la estructura, coloque el ratón en el lugar deseado y haga clic en la etiqueta que desea incluir. Para aprender todo sobre la creación de términos de uso, acceda al menú de ayuda."
        },
        "textDocument": "Documento de texto",
        "presentation": "Presentación",
        "spreadsheet": "Hoja de cálculo",
        "other": "Otros",
        "bookmark": "Favorito|Favoritos"
    },
    "tabs": {
        "personalData": "Datos Personales",
        "corporateData": "Datos Corporativos",
        "academicData": "Datos Académicos"
    },
    "modals": {
        "profile": "Mi perfil",
        "detailsDashboard": "Detalles {title}",
        "select": "Seleccionar",
        "associate": "Asociando {title}",
        "details": "Detalles",
        "adding": "Agregando un {title} | Agregando una {title}",
        "editing": "Editando {title}",
        "insertImage": "Insertar imagen",
        "dissociate": "Desvincular {title}",
        "associateWith": "Asociando {title} en {type}",
        "inscriptions": "Inscripciones {type} {title}",
        "newPassword": "Definir una nueva contraseña",
        "automated": "Jornadas automatizadas en {type} {title}",
        "previewCertificate": "Vista previa del certificado",
        "sectionsOrder": "Definir ordenación de Secciones",
        "associations": "Asociaciones con el objeto {title}",
        "associationsCourses": "Asociaciones con el curso {title}",
        "segmentationsUser": "Usuarios Vinculados",
        "inscriptionsUser": "Inscripciones del Estudiante: {title}",
        "alterPermission": "Cambiar permiso para {num} usuario seleccionado | Cambiar permiso para {num} usuarios seleccionados",
        "addQuestion": "Agregar Pregunta",
        "editQuestion": "Editar Pregunta"
    },
    "validation": {
        "required": "Campo requerido",
        "invalidEmail": "Correo electrónico inválido",
        "passwordMatch": "Las contraseñas deben coincidir",
        "invalid": "Ingrese un {field} válido",
        "basicCharacters": "Solo letras, números y dos caracteres especiales (_-)",
        "max": "El campo no puede exceder {max} caracteres",
        "min": "El campo debe tener al menos {min} caracteres",
        "range": "El valor debe estar entre {0} y {1}",
        "minDuration": "La duración debe ser de al menos 1 minuto.",
        "minQuestions": "Es necesario insertar al menos una pregunta para el usuario.",
        "nameUsed": "Ya existe una etapa con este nombre",
        "selectOption": "Seleccione al menos una opción",
        "sameEmail": "El nuevo correo electrónico no puede ser igual al anterior.",
        "equalsEmail": "Los correos electrónicos deben ser iguales.",
        "fileSizeExceeds": "El tamaño del archivo excede lo sugerido para una buena experiencia de navegación. Adjunte un archivo de hasta {maxSize} {unit} de tamaño.",
        "maxValue": "El valor no puede ser mayor que {max}."
    },
    "feedback": {
        "on": "Activado",
        "off": "Desactivado",
        "copyToClipboard": "¡Copiado al portapapeles!",
        "noResults": "No se encontraron resultados",
        "noResultsSearch": "No se encontraron resultados para la búsqueda realizada.",
        "emailSent": "¡Correo electrónico enviado con éxito!",
        "loading": "Cargando...",
        "updating": "Actualizando...",
        "pleaseWait": "Por favor, espere",
        "processingRequest": "Procesando solicitud",
        "loadingAchievements": "Cargando logros...",
        "loadingRanking": "Cargando clasificación...",
        "noFound": "Ningún ítem encontrado",
        "changeCompany": "¡No es posible cambiar la empresa del contrato!",
        "formError": "Verifique el/los error/es en el formulario",
        "errorLoadObject": "¡No fue posible cargar la información del {object}! Recargue la página e intente nuevamente.",
        "successRequest": "¡Solicitud(es) enviada(s) con éxito!",
        "success": "Éxito",
        "error": "Error",
        "attention": "¡Atención!",
        "failedRequest": "Fallo al enviar la solicitud",
        "errorRequest": "No fue posible enviar la(s) solicitud(es)",
        "wait": "¡Espere!",
        "validationFailed": "Fallo en la validación",
        "formatInvalid": "Formato no soportado para visualización",
        "failedLoadInfo": "Fallo al cargar información",
        "processingData": "Procesando datos...",
        "await": "Aguarde...",
        "loadingTest": "Cargando información de la evaluación...",
        "somethingWrong": "Ups... Algo salió mal",
        "failedLoadItems": "Error al cargar los ítems...",
        "loadingObjects": "Cargando {object}",
        "connectionFail": "Parece que no fue posible restablecer la comunicación con el servicio. Por favor, intente nuevamente en unos instantes.",
        "userNotFound": "No se pudo identificar al usuario, se recomienda actualizar la página.",
        "noPermissionResource": "No tiene permiso para administrar este recurso",
        "successRequestPassword": "¡Solicitud enviada con éxito!",
        "loadingCustomization": "Por favor, espere... Cargando configuraciones del sistema",
        "futureBirthday": "La fecha de nacimiento no puede ser futura",
        "searchPlaceholder": "¿Qué estás buscando?",
        "searchInitFeedback": "Explore los contenidos disponibles en la plataforma: busque entre medios, cursos y rutas para encontrar lo que mejor se adapte a sus necesidades de aprendizaje.",
        "navigate": "Navegar",
        "select": "Seleccionar",
        "close": "Cerrar",
        "recordedResponse": "¡Su respuesta ha sido registrada!",
        "updateAvailable": "¡Actualización disponible!",
        "updateAvailableMessage": "¡Una nueva versión del sistema está disponible! Haga clic aquí o recargue la página para aplicar las actualizaciones.",
        loginSettingsAccess: {
            title: "¡Conoce nuestra nueva pantalla de inicio de sesión!",
            message: "Estamos haciendo algunos ajustes finales, pero ya puedes probar la nueva interfaz. Para acceder a ella, haz clic en el botón de abajo y mira las novedades."
        },
        "feedbackQuestion": "Pronto recibirá comentarios sobre su respuesta. Agradecemos su esfuerzo en la realización del examen.",
        "suggestionTitle": "Sugerencia de Contenido",
        "suggestionSent": "Sugerencia enviada con éxito!",
        "suggestionSentMessage": "¡Gracias por tu sugerencia! Valoramos tu opinión.",
        cantReact: "Solo puedes reaccionar al contenido después de completarlo."
    },
    "login": {
        "welcome": "¡Bienvenido!",
        "forgotPasswordTitle": "Ingrese su correo electrónico de inicio de sesión para que podamos enviar el enlace de restablecimiento de contraseña.",
        "returnLogin": "Volver a la pantalla de inicio de sesión",
        "sendEmail": "Enviar",
        "forgotPassword": "¿Olvidó su contraseña?",
        "helpNeeded": "¿Necesita ayuda para iniciar sesión?",
        "talkToUs": "Hable Con Nosotros",
        "rememberMe": "Recordarme",
        "passwordPlaceholder": "Ingrese su contraseña",
        "emailPlaceholder": "Ingrese su correo electrónico",
        "userFound": "Usuario encontrado...",
        "sessionData": "Registrando datos de la sesión.",
        "loginWith": "Iniciar sesión con la cuenta",
        "authenticated": "¡Usuario autenticado con éxito!",
        "loadingInformation": "Cargando información adicional",
        "accountInformation": "Información de la Cuenta",
        "userData": "Datos del Usuario",
        "userPermissions": "Permisos del Usuario"
    },
    "recover": {
        "title": "¿Olvidó su contraseña?",
        "hintTitle": "Ingrese su correo electrónico registrado en la Plataforma.",
        "hintBody": "Enviaremos un enlace para restablecer su contraseña.",
        "submited": "Una vez que acceda al enlace enviado al correo electrónico <strong>{email}</strong>, será redirigido a la página de restablecimiento de contraseña.",
        "submitedInstructions": "Pronto recibirá un correo electrónico con el enlace para restablecer su contraseña."
    },
    "newPassword": {
        "title": "Defina su nueva contraseña",
        "hintTitle": "Ingrese su nueva contraseña.",
        "hintBody": "Ahora puede acceder a la Plataforma con su nueva contraseña.",
        "rulesText": "Su Nueva Contraseña debe tener",
        "recoverAgain": "Restablecer Contraseña Nuevamente",
        "expired": {
            "title": "Token Inválido/Expirado",
            "msg": "El token de activación es inválido o ya no es válido. Por favor, solicite un nuevo restablecimiento de contraseña o contacte con el soporte."
        },
        "success": {
            "title": "¡Contraseña redefinida con éxito!",
            "msg": "Ahora puede acceder a su cuenta de nuevo."
        },
        "redirectionMsg": "Será redirigido a la pantalla de Acceso a la Plataforma en unos segundos.",
        "rules": {
            "length": "Mínimo de {min} y máximo {max} caracteres",
            "hasLetters": "Caracteres textuales (abc)",
            "case": "Al menos una letra mayúscula",
            "hasNumbers": "Caracteres numéricos (123)",
            "hasSpecialChars": "Caracteres especiales (&$#!)",
            "match": "Ambas contraseñas deben coincidir"
        },
        "validation": {
            "minMax": "La contraseña debe tener entre {min} y {max} caracteres.",
            "letters": "La contraseña debe contener al menos 1 letra.",
            "case": "La contraseña debe contener al menos 1 letra mayúscula.",
            "numbers": "La contraseña debe contener al menos 1 número.",
            "specialChars": "La contraseña debe contener al menos 1 carácter especial."
        }
    },
    "alerts": {
        "youIncredible": "¡Eres increíble!",
        "noNotifications": "Por ahora, ninguna nueva notificación. ¡Aprovecha tus estudios!",
        "dashboardError": "Ocurrió un error al recuperar los datos del panel. Por favor, intente nuevamente en unos minutos.",
        "canCreateUsers": "¡Su contrato alcanzó el número máximo de usuarios activos! Por favor, contacte con el soporte.",
        "loadingPartners": "Cargando los socios, espere un momento.",
        "errorLoadPartners": "No fue posible cargar los socios vinculados a su cuenta, intente nuevamente en unos instantes.",
        "resubscribe": "Si hace click en el botón <strong>Reinscribir</strong>, perderá su contenido ya completado! <br>Para más información, contacte a su gestor o el soporte de la plataforma",
        "scrollTermsOfUse": "El botón Aceptar se habilitará cuando desplace el contenido hasta el final.",
        "document": "En este paso, se necesita un archivo complementario. Haga click en el botón a continuación para realizar la descarga.",
        "errorMediaTitle": "Error al buscar información del medio",
        "errorMediaMessage": "Hubo un error al intentar cargar información del medio. Intente cerrar y abrir nuevamente.",
        "termOfUse": "Este término de uso está vinculado a los parámetros y su estructura no puede ser cambiada.",
        "errorBiContent": "Se produjo un error al intentar cargar la información del panel seleccionado. Por favor, inténtelo de nuevo o verifique si la información del panel registrada es correcta."
    },
    "language": {
        "set": "Elegir idioma",
        "change": "Cambiar idioma"
    },
    "notifications": {
        "activityHistory": "Historial de Actividades",
        "alertEnable": "Haz clic en el botón de abajo para permitir notificaciones en tu navegador.",
        "enable": "Habilitar notificaciones",
        "unsupportedTitle": "¡Notificaciones instantáneas desactivadas!",
        "unsupportedBody": "Tu navegador no admite notificaciones o están desactivadas.",
        "history": "Historial de Notificaciones",
        "uploadHistory": "Historial de Cargas",
        "unread": "Apenas no leídas",
        "markSelectionRead": "Marcar selección como leído"
    },
    "menu": {
        "learning": "Aprendizaje",
        "management": "Gestión",
        "myProgress": "Mi Progreso",
        "dashboard": "Dashboard",
        "myPanel": "Mi Panel",
        "bi": "BI",
        "externalCertificates": "Certificados Externos",
        "accounts": "Cuentas",
        "companies": "Empresas",
        "contracts": "Contratos",
        "units": "Unidades",
        "departments": "Departamentos",
        "jobsTitles": "Puestos",
        "users": "Usuarios",
        "content": "Contenido",
        "repository": "Repositorio",
        "tests": "Evaluaciones",
        "courses": "Cursos",
        "trails": "Rutas",
        "sections": "Secciones",
        "manageExternalCertificates": "Gestionar Certificados Externos",
        "communications": "Comunicaciones",
        "communication": "Comunicación",
        "automatedNotifications": "Notificaciones Automatizadas",
        "gamification": "Gamificación",
        "journey": "Jornada",
        "challenges": "Desafíos",
        "settings": "Configuraciones",
        "customizations": "Personalizaciones",
        "skills": "Competencias",
        "permissions": "Permisos",
        "categories": "Categorías",
        "certificates": "Certificados",
        "termsOfUse": "Términos de Uso",
        "segmentations": "Segmentaciones",
        "rules": "Reglas",
        "myContract": "Mi Contrato",
        "notificationHistory": "Historial de notificaciones",
        "uploadHistory": "Cargar historial",
        "notifications": "Notificaciones",
        biPanels: "Panel BI|Paneles BI",
        "importHistory": "Historial de Importación"
    },
    "topbar": {
        profile: "Perfil",
        settings: "Configuraciones",
        termsAndConditions: "Términos y Condiciones",
        changeCompany: "Cambiar empresa",
        logout: "Salir"
    },
    "mainContent": {
        "sections": {
            "keepLearning": "Continúa Aprendiendo",
            "suggestedForYou": "Sugeridos Para Ti",
            "favorites": "Tus favoritos se verán aquí! <br> Para marcar como favorito, simplemente marque el ícono en la esquina superior presente en el contenido",
            "noFound": "No localizamos ningún resultado en <strong>{text}!</strong> ¿Qué tal si intentas nuevos filtros o cambiar de pestaña?",
            "startLearning": "¿Qué tal si comienzas tu jornada de aprendizaje? <strong>Haz click aquí</strong> y encuentra el contenido ideal para ti!",
            "emptySection": "No tienes ninguna sección activa.<br>¡Comienza ahora mismo a insertar<br>contenidos!¡Haz click aquí!<br>"
        },
        "export": {
            "moveColumns": "¿Mover todas las columnas?",
            "columnsExported": "Columnas a ser exportadas",
            "dragColumns": "Arrastra las columnas aquí",
            "exportAll": "¿Exportar todos los datos?",
            "help": "Arrastre todas las columnas disponibles que desea exportar al área 'Columnas a ser exportadas' a la derecha.",
            "availableColumns": "Columnas disponibles",
            "removeColumns": "Eliminar todas las columnas"
        },
        "units": {
            "addChild": "Agregar Unidad Hija",
            "helpTitle": "¡Cree su estructura organizacional!",
            "createOther": "Puede crear otras <strong>unidades</strong> dentro de una ya existente.",
            "justClick": "Simplemente Haga click",
            "orCreate": "o crear una nueva estructura en la raíz haciendo click"
        },
        "users": {
            "import": {
                "alerts": {
                    "initial": {
                        "title": "Orientaciones para Importación en Lote",
                        "message": "Para la importación en lote, el archivo debe estar en formato .xls o .xlsx. Para continuar con la importación, necesita descargar el archivo modelo, completarlo y enviarlo."
                    },
                    "valid": {
                        "title": "¡El archivo fue validado con éxito!",
                        "message": "Haga click en 'importar datos' para concluir el agendamiento de la importación."
                    },
                    "finish": {
                        "title": "¡Sus datos fueron agendados con éxito!",
                        "message": "Los datos están agendados para la importación. Por favor, verifique la lista de usuarios después de 10 minutos para seguir el progreso."
                    },
                    "processing": {
                        "title": "¡Validando su archivo!",
                        "message": "Seleccione los parámetros y haga click en 'Importar Datos' para concluir."
                    },
                    "failed": {
                        "title": "¡No pudimos validar su archivo! Verifique si es del tamaño y formato correctos, y vuelva a intentarlo.",
                        "message": "Para más detalles sobre la importación"
                    }
                },
                "feedback": {
                    "initial": {
                        "title": "Importe sus datos fácilmente",
                        "message": "El registro en lote ofrece más conveniencia y rapidez para usted, permitiendo la importación de datos para diferentes personas al mismo tiempo."
                    },
                    "processing": {
                        "title": "Estamos validando su archivo...",
                        "message": "Este paso es para asegurar que todo esté correcto con el archivo antes de ser importado."
                    },
                    "failed": {
                        "title": "Resumen de la Importación"
                    },
                    "start": {
                        "title": "La importación está en marcha...",
                        "message": "La importación se completará pronto."
                    }
                },
                "importTemplate": "¿No tiene el modelo para importar usuarios en lote?",
                "download": "Descargar modelo",
                "log": "Descargar el registro",
                "dragdrop": "Arrastre y suelte el archivo .xls / .xlsx aquí o"
            },
            "updateEmail": "Actualización del Correo Electrónico",
            "triggerEmail": "Disparar correo electrónico de inscripción"
        },
        "inscriptions": {
            "import": {
                "feedback": {
                    "initial": {
                        "message": "El registro por lotes ofrece más conveniencia y rapidez para usted, permitiendo la importación de datos para diferentes inscripciones al mismo tiempo."
                    },
                },
                "importTemplate": "¿No tiene el modelo para importar inscripciones por lotes?",
            },
        },
        "repository": {
            "dragDrop": "Seleccione o arrastre un archivo a esta área.<br><br> Considerando la experiencia del usuario al ver videos, recomendamos que sus archivos no superen un tamaño de 1 GB.",
            "generateQr": "Generar QR Code",
            "insertLink": "Insertar Enlace"
        },
        "automatedNotifications": {
            "events": {
                "notAccessEnvironment": "No accedió a la plataforma durante un período de días definido",
                "notStartedCourse": "No inició el curso durante un período de días definido",
                "notStartedTrail": "No inició la ruta durante un período de días definido",
                "expireCourse": "Vencimiento próximo de curso",
                "expireTrail": "Vencimiento próximo de ruta"
            },
            "sendingInterval": {
                "once": "Solo una vez",
                "day": "Una vez al día",
                "week": "Una vez a la semana",
                "month": "Una vez al mes"
            },
            "template": {
                "notAccessEnvironment": "<p>Hemos notado que ha pasado un tiempo desde su última visita a nuestra plataforma. Sabemos lo importante que es mantenerse actualizado y en constante aprendizaje.</p><p>Estamos aquí para recordarle que tenemos una gran variedad de cursos y rutas disponibles para ayudarlo en su crecimiento profesional.</p><p>Aproveche para explorar nuevos contenidos y continuar su viaje de aprendizaje.</p><p>¡Esperamos verlo de nuevo pronto!</p>",
                "notStartedCourse": "<p>Queremos alentarlo a que comience el curso o trayectoria disponible para usted en nuestra plataforma. Nos gustaría recordarle que ha recibido una inscripción, pero aún está pendiente. ¡Acceda a la plataforma y dé el primer paso en su aprendizaje!</p>",
                "expireCourse": "<p>¡Esperamos que esté disfrutando del curso o trayectoria que está tomando con nosotros! Nos gustaría recordarle que la fecha de vencimiento de su curso o trayectoria se está acercando. Aproveche este momento para completar el curso o trayectoria dentro del plazo establecido y continúe invirtiendo en su desarrollo profesional. ¡Estamos ansiosos por verlo alcanzar sus objetivos y esperamos verlo nuevamente pronto!</p>"
            },
            "subject": {
                "notAccessEnvironment": "¡Te extrañamos! Vuelve a la plataforma y sigue aprendiendo",
                "notStartedCourse": "¡No pierda la oportunidad de aprender! Comience su curso/ruta ahora",
                "expireCourse": "Recordatorio: Su curso/ruta está por terminar"
            },
            "needHelp": "<strong>¿Necesita ayuda?</strong> Nuestro equipo de soporte está listo para apoyarlo, simplemente haga click en el ícono de ayuda.",
            "previewFooter": "Este es un correo electrónico automático enviado por Plantar Educación | www.plantareducacion.com",
            "tags": {
                "typeContent": "Tipo de Contenido",
                "limitDate": "Fecha de Vencimiento del Curso/Trayectoria",
                "unit": "Nombre de la Unidad de Negocio del Usuario",
                "department": "Nombre del Departamento del Usuario",
                "jobTitle": "Título del Cargo del Usuario",
                "dateTime": "Fecha y Hora del Sistema"
            }
        },
        "challenges": {
            "environment": "¿En qué ambiente se aplicará el desafío?",
            "typeContent": "¿Para qué tipo de contenido?"
        },
        "certificates": {
            "student": "Nombre del alumno",
            "cpf": "CPF del alumno",
            "jobTitle": "Puesto del alumno",
            "content": "Nombre de la ruta",
            "completed": "Fecha en que el curso/ruta fue completado",
            "duration": "Cantidad de horas de la ruta",
            "certificateId": "Identificador único del certificado",
            "newContent": "Nuevo Contenido",
            "objectiveCompleted": "<p><strong>¡Felicidades!</strong> ¡Has completado los objetivos y mereces este certificado!</p><p>Puede ver y descargar su certificado haciendo click en el botón a continuación.</p>",
            "certificateCompletion": "Certificado de conclusión",
            "sentTestTitle": "¡Gracias! ¡Tu evaluación ha sido recibida!",
            "sentTestMessage": "Su opinión es muy importante para nosotros para mejorar continuamente.",
            "completedProgress": "Ha alcanzado el progreso y/o el aprovechamiento necesario para este contenido",
            "canReturn": "Puede volver y revisar siempre que quiera utilizando también el área de",
            "searchContent": "o buscar nuevos contenidos accediendo al ambiente",
            "generateNow": "¿Qué tal si generas tu certificado ahora mismo?"
        },
        "customizations": {
            "homePage": "Mostrar en la Página de Inicio",
            "collapsedMenu": "Menú Colapsado",
            "menuLogin": "Menú y Login",
            "generalSettings": "Configuraciones Generales",
            "defaultCertificate": "Certificado Predeterminado",
            "logoutAddress": "Dirección de Cierre de Sesión",
            "policyAdress": "Dirección de la Política de Privacidad",
            "backgroundImage": "Imagen de Fondo(s)",
            "boxPosition": "Posición de la Caja",
            "logos": "Logotipos y Favicon",
            "pageTitle": "Título y Descripción de la Página",
            "integrations": "Integraciones",
            "newScript": "Nuevo Script",
            "downloadPdf": "Permitir descarga de documentos PDF",
            "addWatermark": "Agregar marca de agua a los documentos PDF",
            "menuLogoText": "Inserte la imagen para el logotipo del menú interno en formato .JPG o .PNG. Recomendamos imágenes con dimensiones de <strong>90 x 60</strong> píxeles. Adjunte un archivo de hasta <strong>100 kb</strong> de tamaño.",
            "loginLogoText": "Inserte la imagen para el login externo en formato .JPG o .PNG. Recomendamos imágenes con dimensiones de <strong>150 x 150</strong> píxeles. Adjunte un archivo de hasta <strong>100 kb</strong> de tamaño.",
            "logo": "Logo <strong>{type}</strong>",
            "pageIconHelp": "Inserte la imagen para el ícono de la página en formato .PNG para preservar la transparencia del fondo. Recomendamos imágenes con dimensiones de <strong>48 x 48</strong> píxeles. Adjunte un archivo de hasta <strong>100 kb</strong> de tamaño.",
            "pageIcon": "Ícono de la Página <strong>",
            "internalBanners": "Banners Internos",
            "bannerLearning": "Banner de Encabezado del Aprendizaje",
            "bannerLogin": "Imagen de Fondo de la Pantalla de Inicio de Sesión",
            "bannerNewTab": "¿Abrir enlace en una nueva pestaña?",
            login: {
                title: "Configuraciones de la página de inicio",
                configurations: "Configuraciones",
                position: "Posición del cuadro de inicio de sesión",
                newCarousel: "Nuevo carrusel",
                mediaUpload: "Media Upload",
                removeCarousel: {
                    title: "¿Realmente deseas eliminar este elemento?",
                    message: "Esta acción es irreversible y el elemento se eliminará permanentemente. Si lo prefieres, puedes optar por desactivarlo en lugar de eliminarlo."
                }
            }
        },
        "cookies": {
            "title": "Consentimiento de Cookies",
            "content": "Utilizamos cookies y tecnologías similares para mejorar su experiencia, de acuerdo con nuestras",
            "continue": "Al continuar navegando, usted acepta estas condiciones."
        },
        "partner": {
            "access": "¿Qué empresa desea acceder?",
            "notSeeAgain": "¡No deseo ver esta pantalla nuevamente!",
            "currentCompany": "Empresa Actual",
            "otherCompanies": "Otras Empresas",
            "loadList": "Espere un momento mientras cargamos las empresas vinculadas a su cuenta.",
            "search": "La búsqueda por \"{text}\" no obtuvo resultados.",
            "differentTerms": "Intente buscar con términos diferentes. Si necesita asistencia, contacte a nuestro soporte.",
            "loadingOrganization": "Cargando los datos de la organización",
            "defaultCompany": "Esta es mi empresa predeterminada"
        },
        "notAllowed": {
            "restrictedArea": "¡Área Restringida!",
            "noPermission": "Usted no posee los permisos necesarios para acceder a esta página."
        },
        "notFound": {
            "title": "¡Página no encontrada!",
            "content": "La página que intentó ingresar quizás no exista o fue movida."
        },
        "learning": {
            "banner": "¿Qué tal si inserta un banner aquí?<br>¡Haga click para acceder a las configuraciones!"
        },
        "classRoom": {
            "exitClassRoom": "Salir del aula",
            "unavailableContent": "Contenido no disponible",
            "expired": "El tiempo para la realización de su contenido expiró el día: <strong>{date}</strong>",
            "contactNewSubscribe": "Contacte a su gestor o al soporte de la plataforma para solicitar una nueva inscripción",
            "testInfo": "Información sobre evaluación",
            "navigateQuestions": "Navegar por las preguntas",
            "remainingQuestions": "Preguntas restantes",
            "timeOver": "<div>¡El tiempo definido para la realización de esta evaluación ha terminado!</div><div>El cálculo para su aprobación o reprobación se basará en las respuestas enviadas.</div>",
            "testTips": "Separamos algunos consejos para que realice su evaluación sin problemas",
            "completedItems": "artículos completados",
            "approved": "Has sido aprobado",
            "disapproved": "No alcanzaste la nota requerida",
            "tips": "<li>Esté atento al tiempo de duración si la evaluación tiene un límite de tiempo</li><li>Su respuesta solo se enviará cuando haga click en Enviar Respuesta</li><li>Después de enviar la respuesta, no podrá cambiar la alternativa seleccionada</li>",
            "reactionTestTips": "<li>Su respuesta solo se enviará cuando haga click en Enviar Respuesta</li><li>Después de enviar la respuesta, no podrá cambiar la alternativa seleccionada</li><li>Después de finalizar o salir de la evaluación, no podrá ingresar y hacerlo nuevamente</li>"
        },
        "externalCertificates": {
            "approved": {
                "title": "Su solicitud fue aprobada",
                "message": "Su curso ya fue aprobado y registrado"
            },
            "denied": {
                "title": "Su solicitud ha sido denegada",
                "denyPosition": "El certificado no es válido para el puesto ocupado",
                "denyInvalid": "El certificado informado es inválido",
                "denyIllegible": "El certificado informado es ilegible",
                "denyOther": "El certificado informado fue denegado, vea la razón abajo",
                "help": "Puede intentar editar el certificado y enviarlo nuevamente para su aprobación"
            },
            "pending": {
                "title": "Pendiente",
                "message": "Esperando validación y aprobación"
            },
            "incompatibleRole": "Incompatible con el puesto",
            "invalidCertificate": "Certificado inválido",
            "illegibleCertificate": "Certificado ilegible",
            "approvalRequest": "Solicitud de aprobación: {title}",
            "denyReason": "Indique la razón de la denegación",
            "addCertificate": "Añadir tu certificado",
            "dropFile": "<strong>Localiza tu archivo o arrástralo y suéltalo aquí</strong> para enviarlo. Debe estar en formato <strong>png</strong>, <strong>jpg</strong> o <strong>pdf</strong>, y debe tener un tamaño máximo de <strong>2 Mb</strong>."
        },
        "automated": {
            "forUsers": "Para usuarios creados a partir de fecha definida",
            "forAll": "Para todos los usuarios",
            "notifyEmail": "Disparar correo electrónico de inscripción"
        },
        "termsOfUse": {
            "structure": "Estructura del Término de Uso",
            "user": "Nombre del usuario Logueado",
            "unit": "Nombre de la unidad de negocio del usuario logueado",
            "department": "Nombre del departamento del usuario logueado",
            "jobTitle": "Puesto del usuario logueado",
            "dateTime": "Fecha y hora del sistema",
            "date": "Fecha del sistema",
            "hour": "Hora del sistema",
            "defaultContent": "Ingrese el término de uso, que se presentará a los usuarios en la Plataforma."
        },
        "notes": {
            "title": "Mis notas",
            "view": "Ver Mis Notas",
            "insert": "Insertar Mi Nota"
        }
    },
    "column": {
        title: "Título",
        noTitle: "Sin Título",
        "type": "Tipo",
        "situation": "Situación",
        "status": "Estado",
        "expired": "Expirado",
        "required": "Obligatorio",
        "origin": "Origen",
        "subscribedAt": "Inscripto en",
        "completedAt": "Completado en",
        "student": "Alumno",
        "institution": "Institución",
        "createdAt": "Creado en",
        "requestStatus": "Estado de la Solicitud",
        "description": "Descripción",
        "workload": "Carga Horaria",
        "duration": "Duración",
        "startDate": "Fecha de Inicio",
        "endDate": "Fecha de Fin",
        "certificateUrl": "URL del Certificado",
        "credentialCode": "Código de Credencial",
        "credentialUrl": "Enlace de la Credencial",
        "identifier": "Identificador",
        "inscriptionOrigin": "Origen de la Inscripción",
        "name": "Nombre",
        "onGoing": "En Curso",
        "trainingHours": "Horas de Formación",
        "access": "Acceso | Accesos",
        "timeAccess": "Fecha y Hora de Acceso",
        "consumedContent": "Contenido Consumido",
        "userAccess": "Acceso del Usuario | Accesos del Usuario",
        "numAccess": "Cantidad de Accesos | Cantidad de Accesos",
        "lastAccess": "Último Acceso | Últimos Accesos",
        "completedDate": "Fecha de Conclusión",
        "contentName": "Nombre del Contenido",
        "inscriptionDate": "Fecha de Inscripción | Fecha de Inscripciones",
        "consumption": "Consumo",
        "unit": "Unidad | Unidades",
        "phone": "Número de Teléfono",
        "accountId": "ID de la Cuenta",
        "classification": "Clasificación del Cliente",
        "contractDate": "Fecha del Contrato",
        "qtyUsers": "Cantidad de Usuarios",
        "diskSpace": "Espacio en Disco {0}",
        "courseLimit": "Límite de Cursos",
        "trailLimit": "Límite de Trayectos",
        "defaultUrl": "URL Predeterminada",
        "communicationEmail": "Correo Electrónico de Comunicación",
        "contactPersonName": "Nombre de la Persona de Contacto",
        "contactPersonEmail": "Correo Electrónico de la Persona de Contacto",
        "contactPersonPhone": "Teléfono de la Persona de Contacto",
        "companyType": "Tipo de Empresa",
        "managerName": "Nombre del Gerente",
        "managerDocument": "Documento del Gerente",
        "managerEmail": "Correo Electrónico del Gerente",
        "observation": "Observación",
        "customUrl": "URL Personalizada",
        "parentUnit": "Unidad Padre",
        "fileType": "Tipo de Archivo",
        "score": "Nota de Aprobación",
        "showTimer": "Mostrar Temporizador",
        "canRetry": "Permitir Intentos",
        "maxRetries": "Número Máximo de Intentos",
        "maxQuestions": "Número Máximo de Preguntas",
        "shuffleQuestions": "Mezclar Preguntas",
        "shuffleOptions": "Mezclar Opciones",
        "displayAnswers": "Mostrar Respuestas Correctas e Incorrectas",
        "coverLink": "Enlace de la Portada",
        "environment": "Ambiente",
        "sharedSection": "Sección Compartida",
        "bannerLink": "Enlace del Banner",
        "redirectLink": "Enlace de Redirección",
        "initialPubDate": "Fecha de Publicación Inicial",
        "finalPubDate": "Fecha de Publicación Final",
        "period": "Período",
        "alwaysDisplay": "Mostrar Siempre",
        "supervisorCopied": "Supervisor copiado",
        "version": "Versión",
        "registrationDate": "Fecha de Registro",
        "justification": "Justificación",
        "product": "Producto | Productos",
        "dateDispatch": "Fecha de Envío",
        "inscription": "Inscripción | Inscripciones"
    },
    "months": {
        "jan": "Enero",
        "feb": "Febrero",
        "mar": "Marzo",
        "apr": "Abril",
        "may": "Mayo",
        "jun": "Junio",
        "jul": "Julio",
        "aug": "Agosto",
        "sep": "Septiembre",
        "oct": "Octubre",
        "nov": "Noviembre",
        "dec": "Diciembre"
    },
    "gamification": {
        "title": "Siga su viaje",
        "points": "Puntos",
        "ranking": "Clasificación General",
        "myJourney": "Mi Viaje",
        "conquered": "Conquistado en",
        "latestAchievements": "Últimos Logros",
        "status": {
            "error": "No fue posible recuperar la lista de logros.",
            "emptyData": "Todavía no hay datos para mostrar."
        },
        "fullJourney": "Mi viaje completo",
        "earnMorePoints": "Gana más puntos",
        "place": "{position}º lugar",
        "history": "Historial de logros",
        "moorningSun": "Buenos días al sol",
        "nightMoon": "Buenas noches a la luna",
        "nightStars": "Buenas noches a las estrellas",
        "won": "¡Has ganado!",
        "getPoints": "Obtener puntos",
        "achievementReceived": "¡Mira este logro que recibí!",
        "overallRanking": "Clasificación general",
        "unitRanking": "Clasificación en la Unidad"
    },
    "helpers": {
        "profile": "Aquí puede definir algunas reglas de segmentación de contenido por habilidades, actualizar sus datos personales y profesionales, además de elegir esa foto especial para su perfil (en formato .JPG o .PNG, con <strong>200 x 200</strong> píxeles). También puede adjuntar un archivo de hasta <strong>1 MB</strong> de tamaño.",
        "repository": "Aquí, puede insertar objetos de aprendizaje en forma de Enlace Externo, proporcionando acceso a contenidos de video de plataformas como Vimeo, Youtube, entre otros enlaces, incluido el acceso a salas de reuniones en Teams, Zoom y Google Meet.",
        "cardImage": "Inserte la imagen de la tarjeta para el objeto en formatos .JPEG, .JPG, .JFIF, .WEBP, .GIF o .PNG.",
        "recommended": "Recomendamos imágenes con <strong>{dimension}</strong> píxeles. Adjunte un archivo con un tamaño de hasta <strong>{size}</strong>.",
        "objectDetails": "Proporcione detalles para el objeto de aprendizaje.",
        "associateQuestions": "¡Ahora vamos a asociar las preguntas!",
        "structureQuestions": "Aquí, puede definir la estructura de sus preguntas y vincularlas a la evaluación.",
        "courseDetails": "¡Proporcione detalles del curso!",
        "associateObjects": "¡Ahora, vamos a asociar objetos al curso!",
        "createSteps": "¿Qué tal crear los pasos para su ruta?",
        "structureObject": "Aquí, estructurará su curso con objetos ya insertados en el repositorio y materiales adicionales para los alumnos.",
        "stepsCourse": "En esta etapa, puede establecer las principales reglas para el consumo, segmentación y distribución de contenido. ¿Qué tal acceder al menú de <strong>Ayuda</strong> y aprender todo sobre cada parámetro?",
        "stepsTrail": "En las etapas, podrá asociar objetos de aprendizaje, cursos y materiales complementarios",
        "sectionCommunication": "Inserte la imagen para el área de sección del alumno en formatos .JPG o .PNG. Recomendamos imágenes con dimensiones de <strong>1152 x 335</strong> píxeles. Adjunte un archivo con un tamaño máximo de <strong>3 MB</strong>.",
        "associateContent": "¡Ahora, vamos a asociar el contenido!",
        "associateStructure": "Aquí, define la estructura de su sección agregando cursos o rutas para el acceso de los alumnos.",
        "mainRules": "En esta etapa, puede establecer las principales reglas para el consumo, segmentación y distribución de contenido",
        "dontWorry": "¡No se preocupe por completar los filtros; cuando se dejan en blanco, mostrarán todo!",
        "postImage": "<strong>Localice su archivo o arrástrelo y suéltelo aquí</strong> para que sea enviado. Debe estar en formato <strong>png</strong> o <strong>jpg</strong>, y debe tener un <strong>máximo de 2 Mb y 800x600 píxeles.</strong>",
        "associateRules": "¡Ahora vamos a asociar reglas a su desafío!",
        "structureAssocaiteRules": "Aquí creará la estructura de sus desafíos utilizando reglas existentes y luego los vinculará a cursos y rutas.",
        "challengesGeneralRules": "En esta etapa, puede definir las principales reglas para los desafíos y vincularlos a cursos y rutas. ¿Dudas? Simplemente acceda al menú Ayuda y sepa todo sobre cada parámetro.",
        "bannerLearning": "Inserte la imagen para el área interna del alumno en formato .JPG o .PNG. Recomendamos imágenes con dimensiones de <strong>1156 x 384</strong> píxeles. Adjunte un archivo de hasta <strong>3 MB</strong> de tamaño.",
        bannerLogin: "Inserte la imagen para el área externa de inicio de sesión del usuario en formato .JPG o .PNG. Recomendamos imágenes con dimensiones de {dimensions} píxeles. Adjunte un archivo de hasta {fileSize} de tamaño.",
        "readQrcode": "Para leer este código, puede usar una aplicación lectora de QR o algunas aplicaciones de cámara en su teléfono inteligente.",
        "automatedTitle": "¡Ahora vamos a crear las jornadas para sus {object} | ¡Ahora vamos a crear las jornadas para sus {object}!",
        "automatedDescription": "Aquí podrá crear jornadas automatizadas para las inscripciones de sus usuarios. ¿Qué tal si hace click en el botón Asociar y comienza ahora mismo?",
        "automatedRules": "En esta etapa puede definir las principales reglas para su automatización. ¿Qué tal si accede al menú Ayuda y aprende todo sobre cada parámetro?",
        "stageNoItems": "Esta etapa no tiene ítems vinculados",
        "noNotes": "Aquí puedes ver, editar y eliminar notas de tus clases. Te ayudarán a regresar rápidamente a momentos interesantes o desafiantes más tarde.",
        "notAvailable": "que está intentando acceder ya no está disponible para visualización, por favor contacte al administrador",
        "hideSystemChat": "Ocultar chat de ayuda de Plantar Educación",
        "hideSystemChatTT": "Muestra el chat de ayuda de Plantar al hacer clic en el icono de ayuda en el menú superior, o en el texto en la pantalla de inicio de sesión."
    },
    "noContent": {
        "companies": {
            "title": "¡El primer paso es crear las empresas!",
            "message": "Necesita crear empresas (individuos o personas jurídicas) que se incluirán en la secuencia de sus contratos, entendido. Haga click en el botón a continuación y comience a insertar registros.",
            "buttonText": "Nueva Empresa"
        },
        "contracts": {
            "title": "¡Vamos a crear sus Contratos?!",
            "message": "Podrá crear y seguir sus contratos individualmente por cliente: consumo, módulos contratados y proporcionar soporte para procesos del cliente. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nuevo Contrato"
        },
        "units": {
            "title": "¿Vamos a crear su estructura organizacional?",
            "message": "Puede construir la estructura de su unidad con hasta 5 niveles. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Unidad"
        },
        "departments": {
            "title": "¿Vamos a crear sus departamentos?",
            "message": "Aquí, puede crear departamentos para vincular a los usuarios, facilitando la gestión. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nuevo Departamento"
        },
        "jobsTitles": {
            "title": "¿Vamos a crear sus cargos?",
            "message": "Aquí, puede crear cargos para vincular a los usuarios, facilitando la gestión. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nuevo Cargo"
        },
        "users": {
            "title": "¿Vamos a crear usuarios y accesos?",
            "message": "Aquí, puede crear sus usuarios y definir estándares de acceso. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nuevo Usuario"
        },
        "repository": {
            "title": "¿Vamos a crear sus objetos?",
            "message": "¿Qué tal mantener todos sus objetos de aprendizaje en un solo lugar? Aquí, puede categorizarlos por tipos, etc., y usarlos posteriormente asociándolos a cursos y rutas. Haga click en el botón a continuación y comience a agregar objetos.",
            "buttonText": "Nuevo Objeto"
        },
        "tests": {
            "title": "¿Vamos a crear sus pruebas?",
            "message": "Aquí, puede crear pruebas, vincularlas a cursos y rutas, e incluso dirigirlas al público deseado. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Prueba"
        },
        "courses": {
            "title": "¿Vamos a crear sus cursos?",
            "message": "Ahora que ha estructurado objetivos de aprendizaje en el repositorio, puede comenzar a crear sus cursos rápidamente. ¿Qué tal si comienza ahora mismo!",
            "buttonText": "Nuevo Curso"
        },
        "trails": {
            "title": "¿Vamos a crear sus rutas?",
            "message": "Inicie su ruta insertando información general, luego proporcione una sinopsis. A continuación, puede crear etapas y asociar objetos de aprendizaje. ¿Qué tal si comienza ahora mismo?",
            "buttonText": "Nueva Ruta"
        },
        "sections": {
            "title": "¿Vamos a crear sus Secciones?",
            "message": "Aquí puede crear Secciones, vincular cursos, rutas o comunicaciones e incluso dirigirlas al público deseado. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Sección"
        },
        "manageCertificates": {
            "title": "¿Vamos a gestionar Certificados Externos?",
            "message": "Aquí, puede aprobar o negar solicitudes de Certificados Externos enviadas por usuarios de la plataforma."
        },
        "communication": {
            "title": "¿Vamos a crear sus comunicaciones?",
            "message": "Aquí puede crear comunicaciones destacadas, facilitando la comunicación con los usuarios. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Comunicación"
        },
        "notifications": {
            "title": "¿Vamos a crear sus notificaciones?",
            "message": "Aquí puede crear notificaciones automatizadas, que se enviarán basadas en los eventos definidos al registrar. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Notificación"
        },
        "challenges": {
            "title": "¿Vamos a crear sus desafíos?",
            "message": "Aquí puede utilizar las reglas disponibles para crear sus propios desafíos y dirigirlos a cursos y rutas. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nuevo Desafío"
        },
        "skills": {
            "title": "¿Vamos a crear sus competencias?",
            "message": "Aquí puede crear competencias para vincular en la gestión y en contenidos. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Competencia"
        },
        "categories": {
            "title": "¿Vamos a crear sus categorías?",
            "message": "Aquí puede crear categorías para vincular al repositorio, cursos y rutas, facilitando la gestión. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Categoría"
        },
        "segmentations": {
            "title": "¿Vamos a crear sus segmentaciones?",
            "message": "Aquí puede crear sus segmentaciones para vincular a los usuarios, facilitando la gestión. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nueva Segmentación"
        },
        "certificates": {
            "title": "¿Vamos a crear sus certificados?",
            "message": "¿Qué tal crear certificados personalizados para sus cursos y rutas? Haga click en el botón a continuación y comience ahora mismo!",
            "buttonText": "Nuevo Certificado"
        },
        "permissions": {
            "title": "¡Puede crear Grupos de Permisos!",
            "message": "Aquí puede crear sus propios perfiles de acceso, definiendo qué rutinas sus usuarios pueden acceder, creando grupos de permisos. ¿Qué tal si hace click aquí y comienza ahora mismo?",
            "buttonText": "Nuevo Grupo de Permiso"
        },
        "customizations": {
            "title": "¿Vamos a personalizar su ambiente?",
            "message": "Aquí puede configurar su ambiente. ¿Qué tal darle su identidad visual, incluyendo banners, colores, logotipo, entre otros?",
            "buttonText": "Personalizar Ambiente"
        },
        "termsOfUse": {
            "title": "¿Vamos a crear sus términos de uso?!",
            "message": "Aquí puede crear condiciones de uso y vincularlas a la plataforma, cursos o rutas. ¿Qué tal si hace click en el botón a continuación y comienza ahora mismo?",
            "buttonText": "Nuevo término de uso"
        },
        "externalCertificates": {
            "title": "¿Vamos a crear tus Certificados Externos?",
            "message": "Aquí puedes ingresar tus certificados emitidos en otras plataformas. ¿Qué te parece si haces clic en el botón de abajo y comienzas de inmediato?",
            "buttonText": "Nuevo certificado externo"
        }
    },
    "dialogs": {
        "ops": "¡Ups!",
        "confirmation": "¡Confirmación!",
        "setClientMode": {
            "title": "¡Cambio de Ambiente!",
            "message": "<p>Está a punto de acceder al ambiente del cliente para el contrato seleccionado.</p> ¡Atención! Todas las acciones (cambios de diseño, registros, mantenimiento, etc.) afectarán el ambiente de producción del cliente: <strong>{companyName}</strong>. <p>¿Está seguro de que desea continuar?</p>"
        },
        "delete": {
            "title": "Atención",
            "message": "<p>Está a punto de eliminar {type}: <strong>{name}</strong></p><p>¡Esta acción no puede ser deshecha!</p>"
        },
        "recoverPassword": {
            "usersWarningRequest": "No fue posible enviar la(s) solicitud(es) para {0} usuario(s)",
            "usersErrorRequest": "No fue posible enviar la(s) solicitud(es) para <strong>{0}</strong> usuario(s): <strong>{1}</strong>."
        },
        "removePermissions": {
            "title": "Remover permisos",
            "message": "¿Desea remover los permisos del usuario seleccionado? | ¿Desea remover los permisos de los usuarios seleccionados?"
        },
        "recoveryPassword": {
            "message": "Enviando pedido(s) de recuperación de contraseña..."
        },
        "executeManualNotification": {
            "title": "Atención",
            "message": "<p>Estás a punto de ejecutar manualmente el flujo de notificación. Esto enviará correos electrónicos a los estudiantes afectados.</p></br><p>Estás seguro de que deseas continuar?</p>"
        },
        "tests": {
            "moreThanTrue": "<p>Hay más de una opción marcada como verdadera. Si cambia el tipo de pregunta a <strong>objetiva</strong>, todas las opciones se marcarán como 'falsas'.</p><p>¿Está seguro de que desea continuar?</p>",
            "removeQuestion": "¿Realmente desea eliminar la pregunta indicada?",
            "removeSkills": "¿Realmente desea eliminar <strong>{name}</strong>?"
        },
        "sections": {
            "changeSegmentation": "<p>Esta sección ya tiene segmentaciones definidas. Continuar removerá esos enlaces.</p><p>¿Desea continuar?</p>",
            "changeDepartment": "<p>Esta sección ya tiene una unidad, departamento o puesto definido. Continuar removerá esos enlaces.</p><p>¿Desea continuar?</p>"
        },
        "challenges": {
            "deleteRule": "¿Realmente desea eliminar la regla indicada?"
        },
        "certificates": {
            "remove": "¿Realmente desea eliminar este elemento?"
        },
        "customizations": {
            "changeTermOfUse": "<p>Ha cambiado los términos de uso predeterminados, por lo que todos los estudiantes serán notificados en su próximo ingreso para aceptar los nuevos términos.</p><p>¿Desea continuar?</p>",
            "removeBanner": "<p>¿Está seguro de que desea eliminar el banner? <strong>Esta acción no puede ser deshecha</strong>!</p><p>Sin embargo, si lo desea, puede insertar uno o más banners utilizando esta rutina.</p>",
            "removeBgLogin": "<p>Esta imagen de fondo se veía bien :(.</p><p>¿Realmente puedo decirle adiós?...<p><strong>Presione 'confirmar', y la imagen se irá</strong>.</p>"
        },
        "partner": {
            "title": "Definir como empresa predeterminada",
            "message": "<p>¿Realmente desea definir a la empresa: <strong>{name}</strong> como predeterminada?</p>"
        },
        "externalCertificates": {
            "title": "¿Aprobar certificado externo?",
            "message": "<p>¿Desea aprobar este curso? el alumno será notificado de inmediato</p>"
        },
        "inscriptions": {
            "removeMoreThanOne": "¿Desea cancelar <strong>{count}</strong> inscripciones seleccionadas?",
            "removeOne": "¿Desea cancelar la inscripción del alumno seleccionado?"
        },
        "automated": {
            "inscriptions": "<p>Si tiene inscripciones de alumnos vinculadas a este curso o ruta, se cancelarán al confirmar.</p>¿Desea continuar?<p>"
        },
        "repository": {
            "fileExists": "Ya existe un archivo adjunto, ¿desea proceder? (En caso afirmativo, el archivo se desvinculará)",
            "linkExists": "Ya existe un enlace insertado, ¿desea proceder? (En caso afirmativo, el enlace se eliminará)",
            "removeFile": "¿Realmente desea eliminar los medios actuales?"
        }
    },
    "toasts": {
        "failedLoadingMap": "¡No fue posible cargar el mapa de estados! Recargue la página e intente nuevamente",
        "invalidFile": "Formato de archivo inválido",
        "acceptFiles": "Los formatos aceptados son {0}",
        "updateSuccess": "Registro actualizado con éxito",
        "createSuccess": "Registro creado con éxito",
        "removeSuccess": "Registro eliminado con éxito",
        "sentSuccess": "Registro enviado con éxito",
        "contactManager": "Contacte a su gestor",
        "removePermissions": {
            "sending": "Eliminando permisos",
            "successTitle": "¡Permisos eliminados con éxito!",
            "successMessage": "Los permisos han sido eliminados"
        },
        "repository": {
            "objectNoFile": "¡No es posible guardar un objeto sin un archivo!",
            "objectNoLink": "¡No es posible guardar un objeto sin insertar un enlace válido!",
            "scormType": "¡No es posible guardar un Scorm sin especificar su tipo!",
            "cancelTransfer": "Cancelada por el usuario",
            "uploadCover": "de la imagen de portada",
            "invalidQrcode": "¡Enlace de archivo inválido para generar QRCode!"
        },
        "tests": {
            "trueOrFalse": "Solo es posible insertar dos opciones en preguntas de Verdadero/Falso.",
            "noDescription": "¡Hay opciones sin descripciones!",
            "oneTrue": "¡Es necesario insertar al menos una opción correcta!",
            "addOption": "Es necesario insertar una opción",
            "notSave": "¡La evaluación no puede guardarse sin ninguna pregunta!"
        },
        "communication": {
            "requiredPost": "¡Es necesario insertar una publicación!",
            "requiredTitle": "¡Es necesario insertar un título o imagen en la publicación!"
        },
        "challenges": {
            "addOption": "¡Es necesario asociar un ítem para avanzar a la siguiente etapa!",
            "points": "¡La puntuación debe ser mayor que cero!",
            "itemToCreate": "¡Es necesario asociar un ítem para crear el desafío!"
        },
        "skills": {
            "observation": "Proporcione un resumen sobre la competencia que está incluyendo."
        },
        "login": {
            "errorLoadingInfo": "Error al cargar información adicional"
        },
        "sections": {
            "contractLinked": "¡Contratos vinculados con éxito!",
            "sortingSaved": "¡Ordenación guardada con éxito!"
        },
        "users": {
            "updatedEmail": "¡Correo electrónico actualizado con éxito!",
            "defaultAccount": "¡Cuenta predeterminada definida con éxito!",
            "accessValidated": "¡Su acceso ha sido validado!",
            "resetPassword": "¡Contraseña redefinida!",
            "welcomeAliare": "Bienvenido a Aliare",
            "updatedPassword": "¡Su contraseña ha sido actualizada con éxito!"
        },
        "inscriptions": {
            "subscribe": "¡{0} alumnos inscriptos con éxito!",
            "unsubscribe": "¡{0} inscripciones canceladas con éxito!"
        },
        "system": {
            "saveSuccess": "Parámetros registrados con éxito"
        },
        "classRoom": {
            "giveUp": "<p>Al salir de la evaluación perderá el progreso en curso. Si su evaluación tiene solo un intento, se considerará como reprobado en esta evaluación.</p>",
            "looseInProgress": "<p>Al salir de la evaluación perderá el progreso en curso. Si su evaluación tiene solo un intento, se considerará <strong>reprobado</strong> en esta evaluación.</p> ¿Desea continuar?",
            "notReturn": "<p>Al salir de la evaluación no podrá regresar para realizarla. Si ha llenado solo parte de las alternativas, las respuestas serán desconsideradas.</p>¿Desea continuar?",
            "notAnswerReaction": "<p></p>¿No desea responder la evaluación de reacción? <strong>¡No hay problema! Al hacer click en confirmar, marcaremos esta evaluación como completada. ¿De acuerdo?</strong></p>"
        },
        "externalCertificates": {
            "selectJustification": "¡Es necesario seleccionar una justificación!"
        },
        "dashboard": {
            "generatingErrorTitle": "Error al generar certificado",
            "generatingErrorMessage": "No fue posible generar el certificado, intente nuevamente más tarde"
        },
        "details": {
            "registrationSuccess": "¡Inscripción realizada con éxito!",
            "registrationCanceled": "¡Inscripción cancelada con éxito!",
            "favoriteSuccess": "¡Elemento agregado a favoritos con éxito!",
            "favoriteCanceled": "¡Elemento eliminado de favoritos con éxito!",
            "reactionSuccess": "Reacción añadida con éxito",
            "reactionCanceled": "Reacción eliminada con éxito"
        }
    },
    "steps": {
        "basicData": "Datos Básicos",
        "offer": "Oferta",
        "settings": "Configuraciones",
        "contractManager": "Gestión de Contratos",
        "generalRules": "Reglas Generales",
        "generalData": "Datos Generales",
        "associateObjects": "Asociar Objetos"
    },
    "errors": {
        "notSupportStreaming": "Soporte para streaming no es soportado por el navegador"
    },
    "repository": {
        "title": "Repositorio",
        "label": {
            "classic": "Repositorio Clásico",
            "modern": "Repositorio Moderno",
            "displayDuration": "Mostrar Duración",
            "displayCode": "Mostrar Código",
            "displayCreatedAt": "Mostrar Fecha de Creación",
            "displayOrigin": "Mostrar Origen",
            "displayExpired": "Mostrar Expirado",
            "displayRequired": "Mostrar Obligatorio",
            "subtitle": "Subtítulo|Subtítulos",
            "chapter": "Capítulo|Capítulos",
            "clickTovisit": "Haga click aquí para visitar el enlace",
            "unavailable": "Indisponible"
        },
        "help": {
            "supportedFilesTitle": "¡Formatos de archivo admitidos!",
            "supportedFilesMessage": "Puede insertar varios tipos de objetos de aprendizaje como: Video, PDF, Audio, Imagen, Documento y Scorm.",
            "supportedLinksTitle": "¡Formatos de enlace admitidos!",
            "supportedLinksMessage": "Puede insertar enlaces de plataformas como Vimeo, Youtube, entre otros enlaces, incluido el acceso a salas de reuniones en Teams, Zoom y Google Meet.",
            "unavailable": "Para obtener más información, comuníquese con su gerente o con el soporte de la plataforma.",
            "cantUploadSubtitlesYet": "Solo es posible agregar subtítulos después de que el video se haya subido y preprocesado.",
            "cantUploadChaptersYet": "Solo es posible agregar capítulos después de que el video se haya subido y preprocesado.",
            "chaptersIntroduction": "Los capítulos se muestran en la línea de tiempo del video y permiten que los espectadores naveguen con más facilidad.",
            "noChaptersAddedYet": "No se han agregado capítulos a este video."
        },
        "feedback": {
            "chapterSLE": "El inicio del capítulo debe ser menor que el final.",
            "chapterEGV": "El final del capítulo no puede ser mayor que la duración del video.",
            "chapterOverlap": "Un capítulo no puede superponerse a otro capítulo."
        },
        "button": {
            "generateQrCode": "Generar QRCode"
        },
        "form": {
            "objectType": "Tipo de objeto",
            "objectDuration": "Duración del objeto",
            "durationAutoDetect": "Detectar automáticamente",
            "create": "Crear un objeto",
            "edit": "Editar objeto",
            "downloadable": "Descargable",
            "selectScormType": "Seleccione el tipo de Scorm",
            "selectCategory": "Seleccione una categoría",
            "watermark": {
                "title": "Marca de agua",
                "enable": "Habilitar marca de agua",
                "userName": "Nombre de usuario",
                "userEmail": "Correo electrónico del usuario",
                "company": "Empresa",
                "unit": "Unidad",
                "department": "Departamento",
                "position": "Puesto",
                "dateTime": "Fecha y hora",
                "userIp": "IP del usuario"
            },
            "chapter": {
                "start": "Inicio del Capítulo",
                "end": "Fin del Capítulo",
                "title": "Título del Capítulo"
            }
        }
    },
    "permissionGroup": {
        "form": {
            "titleAdd": "Nuevo Grupo de Permisos",
            "titleEdit": "Editar - {name}",
            "name": "Nombre del Grupo de Permisos",
            "namePlaceholder": "Ingrese el nombre del grupo de permisos",
            "hint1": "Seleccione todas las funcionalidades que se pondrán a disposición de los usuarios de este grupo.",
            "selectFunctionality": "Seleccione las funcionalidades"
        }
    },
    "versionManager": {
        "newVersion": "Nueva versión",
        "newAvailable": "¡Nueva versión disponible!",
        "newInstructions": "Haga clic en el botón para actualizar la página y obtener la última versión.",
        "update": "Actualizar"
    },
    role: {
        all: "Todos",
        admin: "Administrador",
        manager: "Gestor",
        student: "Alumno"
    },
    dashboard: {
        splash: {
            title: "¡Explora tus Dashboards!",
            description: "Accede a los paneles disponibles en la barra superior y visualiza datos esenciales para monitorear el rendimiento y tomar decisiones estratégicas."
        }
    },
    assessmentGiveupResult: {
        title: '¡Desistimiento registrado!',
        message: 'Puede comenzar una nueva evaluación si aún hay intentos disponibles.'
    },
    contract: {
        label: {
            enableAAD: 'Permitir autenticación SSO por AAD',
            AAD: {
                id: "ID del Cliente AAD",
                tenantId: "Tenant ID",
                url: "URL de la instancia AAD",
                redirectUri: "URL de retorno de autenticación AAD",
                provider: "Provider"
            }
        },
        tooltip: {
            enableAAD: "SSO (Single Sign-On) permite acceder a múltiples sistemas con una sola autenticación. AAD (Azure Active Directory) es la plataforma de Microsoft que gestiona esta autenticación, ofreciendo más seguridad y comodidad."
        },
        placeholder: {
            AAD: {
                id: "Ingrese el ID del Cliente AAD",
                tenantId: "Ingrese el Tenant ID",
                url: "Ingrese la URL de la instancia AAD",
                redirectUri: "Ingrese la URL de retorno de autenticación AAD",
                provider: "Ingrese el Provider"
            }
        }
    }
};
