import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useStorageAsync } from '@vueuse/core';
import { dialog, toast, i18N } from '@/common/util';
import contratosService from '@/services/contratos.service';
export const useContratosStore = defineStore('contratos', () => {
    /**
     * States
     */
    const list = ref([]);
    const usageInfo = reactive({ qtUsers: 0, qtInContract: 0 });
    const nStatus = ref({
        getAll: 'initial',
        create: 'initial',
        update: 'initial',
        remove: 'initial'
    });
    /**
     * Getters
    */
    const loading = computed(() => nStatus.value.getAll === 'loading');
    const isBusy = computed(() => Object.values(nStatus.value).some(el => el === 'loading'));
    const canCreateUsers = computed(() => (usageInfo.qtUsers === 0 && usageInfo.qtInContract === 0) || usageInfo.qtUsers < usageInfo.qtInContract);
    const isDistribuidor = useStorageAsync('isDistribuidor', false, localStorage, { mergeDefaults: true });
    /**
     * Actions
     */
    const create = async (objData) => {
        nStatus.value.create = 'loading';
        try {
            const { success } = await contratosService.create(getFormSubmit(objData));
            if (success) {
                getAll();
                toast.success({ message: i18N('toasts.createSuccess') });
            }
            nStatus.value.create = 'success';
            return success;
        }
        catch (error) {
            console.error(error);
            nStatus.value.create = 'error';
        }
    };
    const update = async (objData, id) => {
        nStatus.value.update = 'loading';
        try {
            objData.contratosComunicacoes = objData.contratosComunicacoes.map((el) => {
                if (!el.comunicacaoId)
                    return { comunicacaoId: el };
                return el;
            });
            const { success } = await contratosService.put(id, objData);
            if (success) {
                getAll();
                toast.success({ message: i18N('toasts.updateSuccess') });
            }
            nStatus.value.update = 'success';
            return success;
        }
        catch {
            nStatus.value.update = 'error';
        }
    };
    const getAll = async () => {
        nStatus.value.getAll = 'loading';
        try {
            const { data } = await contratosService.get();
            list.value = data;
            nStatus.value.getAll = 'success';
        }
        catch {
            nStatus.value.getAll = 'error';
        }
    };
    const remove = async (contrato, force) => {
        if (!force)
            return dialog.open({
                title: i18N('feedback.attention'),
                message: i18N('dialogs.delete.message', { type: i18N('labels.contract').toLowerCase(), name: contrato.nomeEmpresa }),
                complete: () => remove(contrato, true)
            });
        try {
            nStatus.value.remove = 'loading';
            const { success } = await contratosService.delete(contrato.id);
            if (success)
                list.value.splice(list.value.findIndex(el => el.id === contrato.id), 1);
            nStatus.value.remove = 'success';
            return success;
        }
        catch {
            nStatus.value.remove = 'error';
        }
    };
    const getById = async (id) => {
        try {
            const { data } = await contratosService.get(id);
            return data;
        }
        catch {
            //
        }
    };
    const getIsDistribuidor = async () => {
        try {
            const { data, success } = await contratosService.isDistribuidor();
            if (success)
                isDistribuidor.value = data;
        }
        catch {
            //
        }
    };
    const getUsageInfo = async () => {
        try {
            const { data, success } = await contratosService.getUsageInfo();
            if (success) {
                Object.assign(usageInfo, {
                    qtUsers: data.usuarioUsageInfo?.usuariosAtivos,
                    qtInContract: data.usuarioUsageInfo?.usuariosContratados
                });
            }
        }
        catch {
            //
        }
    };
    const getFormSubmit = (data) => {
        return {
            ...data,
            contratosSecoes: data.secoesIds.map(el => ({ secaoId: el })),
            contratosComunicacoes: data.contratosComunicacoes.map(el => ({ comunicacaoId: el }))
        };
    };
    return {
        list,
        isDistribuidor,
        usageInfo,
        // Getters
        isBusy,
        status: computed(() => nStatus.value),
        loading,
        canCreateUsers,
        // Actions
        create,
        update,
        getAll,
        getById,
        remove,
        getIsDistribuidor,
        getUsageInfo
    };
});
export default useContratosStore;
