import BaseService from './base.service';
import baseApi from './base.api';
export const assessmentService = {
    ...baseApi,
    resource: 'avaliacao',
    /**
     * Retorna as informações básicas de uma avaliação a ser realizada
     *
     * @param opts - Objeto com os parâmetros da requisição
     * @param opts.assessmentId - Id da avaliação
     * @param opts.courseId - Id do curso
     * @param opts.trailId - Id da trilha
     * @returns
     */
    info(opts) {
        return assessmentService.get(`basic`, {
            avaliacaoId: opts.assessmentId,
            cursoId: opts?.courseId || null,
            trilhaId: opts?.trailId || null
        });
    },
    /**
     * Inicia uma avaliação
     *
     * @param opts - Objeto com os parâmetros da requisição
     * @param opts.assessmentId - Id da avaliação
     * @param opts.courseId - Id do curso
     * @param opts.trailId - Id da trilha
     * @returns
     */
    start(opts) {
        return assessmentService.post(`student/start`, {
            avaliacaoId: opts.assessmentId,
            cursoId: opts.courseId || null,
            trilhaId: opts.trailId || null
        });
    },
    skipEvalution(opt) {
        return assessmentService.post(`student/finish/dontAnswer`, {
            avaliacaoId: opt.assessmentId,
            cursoId: opt?.courseId || null,
            trilhaId: opt?.trailId || null
        });
    }
};
class AvaliacaoService extends BaseService {
    constructor() {
        super({ resource: 'avaliacao' });
    }
    async getBonds(avaliacaoId) {
        return this.plantarApi.get(`${this.resource}/${avaliacaoId}/associations`);
    }
    async getInfo(opt) {
        try {
            const { success, data } = await assessmentService.info({
                assessmentId: opt.testId,
                courseId: opt.courseId,
                trailId: opt.trailId
            });
            if (success) {
                return {
                    success,
                    data: {
                        id: opt.testId,
                        title: data.titulo,
                        observation: data.observacao,
                        maxQuestions: data.totalQuestoes,
                        duration: data.duracaoDaAvaliacao
                            ?.split(':')
                            .reverse()
                            .reduce((prev, curr, i) => prev + parseInt(curr) * Math.pow(60, i), 0) || 0,
                        canRetry: data.politicaRetentativa,
                        retries: data.tentativasRealizadas,
                        maxRetries: data.maximoTentativas,
                        latestRetry: null,
                        score: data.pontuacaoMinima,
                        type: ['Diagnostica', 'Reacao', 'Aprendizagem', 'Comportamento'].findIndex((val) => val === data.tipoDeAvaliacao),
                        displayAnswers: data.exibirCorretaseErradas,
                        showTimer: data.exibirTimer,
                        continue: data.continuar,
                        totalQuestionsAnswered: data.totalQuestoesRespondidas,
                        totalQuestionsToAnswer: data.totalQuestoesParaResponder
                    }
                };
            }
        }
        catch (error) {
            //
        }
        return {
            success: false
        };
    }
    async start(opt) {
        try {
            const { success, data } = await assessmentService.start({
                assessmentId: opt.testId,
                courseId: opt.courseId,
                trailId: opt.trailId
            });
            return {
                success,
                data: {
                    id: data.id,
                    continue: data.continuar,
                    startTime: data.dataInicio,
                    questionsAnswered: data.questoesRespondidas
                }
            };
        }
        catch (error) {
            //
        }
        return { success: false };
    }
    async nextQuestion(userTestId) {
        return this.plantarApi.get(`${this.resource}/student/${userTestId}/nextQuestion`);
    }
    async answer(answer) {
        try {
            const { success, data } = await this.plantarApi.post(`${this.resource}/student/answer`, {
                usuarioAvaliacaoId: answer.userTestId,
                questaoId: answer.questionId,
                alternativasIds: answer.answersId,
                texto: answer.answerDiscourse,
                escala: answer.scale
            });
            return {
                success,
                data: {
                    correct: data.acertou,
                    partiallyCorrect: data.acertouParcial,
                    correctAnswers: data.alternativasCorretasIds,
                    userAnswers: data.alternativasRespondidasIds,
                    scale: data.escala,
                    answerDiscourse: data.texto,
                    hit: data.feedbackAcerto,
                    miss: data.feedbackErro
                }
            };
        }
        catch (error) {
            return { success: false };
        }
    }
    async results(userTestId) {
        try {
            const { success: s1, data: result } = await this.plantarApi.post(`${this.resource}/student/${userTestId}/finish`);
            const { success: s2, data: info } = await this.getInfo({
                courseId: result.cursoId,
                trailId: result.formacaoId,
                testId: result.avaliacaoId
            });
            return {
                success: s1 && s2,
                data: {
                    userTestId: result.usuarioAvaliacaoId,
                    title: info.title,
                    completed: result.concluida,
                    retries: info.retries,
                    maxRetries: info.maxRetries,
                    date: new Date(result.dataAvaliacao),
                    score: result.pontuacaoFinal,
                    duration: result.tempoGastoEmMinutos,
                    approved: result.aprovado,
                    hit: result.acertos,
                    miss: result.erros,
                    type: info.type,
                    courseCompleted: result?.aprovacao?.status === 'Aprovado'
                }
            };
        }
        catch (error) {
            return { success: false };
        }
    }
    async giveUp(userTestId) {
        if (!userTestId)
            return Promise.resolve({ success: false });
        try {
            const { success } = await this.plantarApi.put(`${this.resource}/student/${userTestId}/finish/giveup`);
            return { success };
        }
        catch (error) {
            return { success: false };
        }
    }
}
const avaliacaoService = new AvaliacaoService();
export default avaliacaoService;
