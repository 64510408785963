export const ptLocale = {
    "input": {
        "observation": "Observação|Observações",
        "status": "Status",
        "competency": "Competência|Competências",
        "objectCode": "Código do Objeto",
        "uniqueIdentifier": "Identificador Único {name}",
        "search": "Buscar",
        "showInCourses": "Mostrar em Cursos",
        "showInFormations": "Mostrar em Trilhas",
        "showInMidias": "Mostrar em Mídias",
        "nickname": "Apelido",
        "document": "Documento | Documentos",
        "foreignDocument": "Documento Estrangeiro",
        "email": "E-mail",
        "password": "Senha",
        "confirmPassword": "Confirmar Senha",
        "phone": "Número de Telefone",
        "gender": "Gênero | Gêneros",
        "birthDate": "Data de Nascimento",
        "preferredLanguage": "Idioma Preferido",
        "photoUpload": "Upload de Foto",
        "personType": "Tipo de Pessoa",
        "admissionDate": "Data de Admissão",
        "role": "Função",
        "directSupervisor": "Superior Imediato",
        "superiorsInterests": "Interesses do Superior",
        "userInterests": "Interesses do Usuário",
        "smsNotifications": "Notificações por SMS",
        "pushNotifications": "Notificações Push",
        "public": "Público",
        private: "Privado",
        "dragAndDrop": "Arraste e solte o arquivo aqui <br>ou",
        "category": "Categoria | Categorias",
        "expirationDate": "Data de Expiração",
        "resume": "Currículo",
        "createdAt": "Data de Criação",
        "certificateStructure": "Estrutura de Certificado",
        "notShowAgain": "Não exibir este item novamente",
        "copyTitle": "Cópia - {title}",
        "paperSize": "Tamanho do Papel",
        "dpi": "DPI",
        "orientation": "Orientação"
    },
    "button": {
        "ok": "Ok",
        "close": "Fechar",
        "save": "Salvar",
        cancel: "Cancelar {text}",
        remove: "Remover {text}",
        disable: "Desativar {text}",
        enable: "Ativar {text}",
        "viewTermsOfUse": "Ver Termos de Uso",
        "login": "Login",
        "back": "Voltar",
        "send": "Enviar",
        "myLearning": "Meu Aprendizado",
        "learnMore": "Aprenda Mais",
        "myFavorites": "Meus Favoritos",
        "continue": "Continuar",
        "subscribe": "Inscrever",
        "read": "Ler | Lido",
        "expired": "Expirado",
        "fullscreen": "Alternar Tela Cheia",
        "listen": "Ouvir",
        "watch": "Assistir",
        "loadMore": "Carregar Mais",
        "details": "Detalhes",
        "seeAll": "Ver Tudo",
        "seeAgain": "Ver Novamente",
        "listenAgain": "Ouvir Novamente",
        "start": "Iniciar",
        "view": "Visualizar",
        "classRoom": "Sala de Aula",
        "access": "Acessar",
        "download": "Download {text}",
        "darkMode": "Modo Escuro",
        "lightMode": "Modo Claro",
        "export": "Exportar",
        "clear": "Limpar {text}",
        "today": "Hoje",
        "yesterday": "Ontem",
        "thisWeek": "Esta Semana",
        "thisMonth": "Este Mês",
        "resetPassword": "Redefinir Senha",
        "delete": "Excluir {text}",
        "markAsRead": "Marcar como lido",
        "update": "Atualizar",
        "add": "Adicionar {text}",
        "list": "Lista",
        "seeMore": "Ver Mais",
        "confirm": "Confirmar",
        "help": "Ajuda",
        "clientMode": "Modo Cliente",
        "next": "Avançar",
        "saveExit": "Salvar e Sair",
        "expand": "Expandir",
        "collapse": "Recolher",
        "hide": "Ocultar",
        "edit": "Editar",
        "automation": "Automação",
        "import": "Importar",
        "changePermission": "Alterar Permissão",
        "removePermission": "Remover Permissão",
        "changePassword": "Alterar Senha",
        "changeEmail": "Alterar E-mail",
        "validateFile": "Validar Arquivo",
        "importData": "Importar Dados",
        "newImport": "Nova Importação",
        "processing": "Processando...",
        "associations": "Associações",
        "changeImage": "Alterar Imagem",
        "viewPreview": "Visualizar Prévia",
        "addYoutubeLink": "Adicionar link do YouTube",
        "insert": "Inserir",
        "new": "Novo {text} | Nova {text}",
        "text": "Texto",
        "htmlCode": "Código HTML",
        "button": "Botão",
        "checkAll": "Marcar todos",
        "uncheckAll": "Desmarcar todos",
        "checkAllVisible": "Marcar visíveis",
        "uncheckAllVisible": "Desmarcar visíveis",
        "revert": "Reverter",
        "accept": "Aceitar",
        "tryAgain": "Tentar Novamente",
        "set": "Definir",
        "exit": "Sair",
        "applyFilters": "Aplicar Filtros",
        "resubscribe": "Reinscrever-se",
        "approve": "Aprovar",
        "deny": "Negar",
        "generateCertificate": "Gerar certificado",
        "currentItem": "Ir para questão atual",
        "nextQuestion": "Próxima Questão",
        "submitAnswer": "Enviar Resposta",
        "finish": "Finalizar",
        "startTest": "Iniciar avaliação",
        "continueTest": "Continuar avaliação",
        "notAnswer": "Não responder",
        "returnLogin": "Retornar ao Login",
        "enterLogin": "Entrar",
        "goClassRoom": "Ir para a Sala de Aula",
        "editUserEmail": "Editar e-mail do usuário",
        "studentInscriptions": "Inscrições dos alunos",
        "createQrCode": "Criar código QR para este objeto",
        "automateJourneys": "Automatizar jornadas",
        "reviewRequest": "Analisar solicitação",
        "print": "Imprimir {text}",
        "firstPage": "Primeira página {text}",
        "press": "Pressione {text}",
        "nextPage": "Próxima página {text}",
        "lastPage": "Última página {text}",
        "unselectSelected": "Desmarcar selecionados",
        "removeSelected": "Remover selecionados",
        "clone": "Clonar",
        "executeFlow": "Executar o fluxo de notificação manualmente",
        "accessLink": "Acessar Link",
        "exitContract": "Sair do contrato",
        "listing": "Listagem",
        "nextContent": "Próximo Conteúdo",
        "previousContent": "Conteúdo Anterior",
        "leaveClassroom": "Sair da Sala de Aula",
        "addQuestions": "Adicionar Perguntas",
        "backListAssessment": "Voltar para Lista de Avaliações de Conhecimento",
        "detailsAssessment": "Visualizar Detalhes da Avaliação",
        "finishAssessment": "Finalizar Avaliação",
        "continueAssessment": "Continuar Avaliação",
        "endAssessment": "Encerrar Avaliação",
        "sendSuggestion": "Enviar Sugestão",
        "goToLoginSettings": "Ir para Configurações de Login"
    },
    "labels": {
        row: "Linha|Linhas",
        "denied": "Negado",
        "course": "Curso | Cursos",
        "document": "Documento | Documentos",
        "trail": "Trilha | Trilhas",
        "link": "Link | Links",
        "visitLink": "Visitar Link",
        "video": "Vídeo|Vídeos",
        "podcast": "Podcast | Podcasts",
        "scorm": "Scorm | Scorms",
        "pdf": "PDF | PDFs",
        "image": "Imagem | Imagens",
        "photo": "Foto|Fotos",
        value: "Valor|Valores",
        "of": "de",
        "items": "Itens",
        "showing": "Exibindo",
        "progress": "Progresso",
        "all": "Todos | Todas",
        "media": "Mídia|Mídias",
        "actions": "Ações | Ação",
        "notStarted": "Não iniciado",
        "complete": "Completo",
        "completed": "Concluído",
        "listPerPage": "Lista por página",
        "selected": "Selecionado|Selecionados",
        "pagination": "Mostrando {min} - {max} de {total}",
        "currentPage": "{current} de {total} páginas",
        "fullname": "Nome Completo",
        "calledBy": "Prefiro ser chamado por",
        "preferLanguage": "Meu idioma preferido",
        "skill": "Competência | Competências",
        "supervisor": "Superior Imediato",
        "educationLevel": "Nível de Educação",
        "unit": "Unidade | Unidades",
        "department": "Departamento | Departamentos",
        "jobTitle": "Cargo | Cargos",
        "pending": "Pendente",
        "approved": "Aprovado",
        "failed": "Falhou",
        "onGoing": "Em andamento",
        "total": "Total",
        "content": "Conteúdo | Conteúdos",
        "user": "Usuário | Usuários",
        "active": "Ativo|Ativos",
        "inactive": "Inativo|Inativos",
        "visible": "Visível|Visíveis",
        "hidden": "Oculto|Ocultos",
        "yes": "Sim",
        "no": "Não",
        "filter": "Filtro | Filtros",
        "cnpj": "CNPJ",
        "cpf": "CPF",
        "naturalPerson": "Física",
        "legalEntity": "Jurídica",
        "foreign": "Estrangeiro",
        "companyName": "Nome da Empresa",
        "landlineNumber": "Número Fixo",
        "mobilePhone": "Telefone Móvel",
        "fantasyName": "Nome Fantasia",
        "nameOrCompany": "Nome ou Nome da Empresa",
        "district": "Bairro",
        "state": "Estado",
        "city": "Cidade",
        "country": "País",
        "homeNumber": "Número Residencial",
        "address": "Endereço",
        "adjunct": "Complemento",
        "customerActivity": "Atividade do Cliente",
        "zipCode": "CEP",
        "number": "Número",
        "company": "Empresa",
        "aliareCode": "Código Aliare",
        "date": "Data",
        "projectStatus": "Status do Projeto",
        "aliareProducts": "Produtos Aliare",
        "numContractedUsers": "Limite de usuários",
        "contractedDiskSpace": "Limite Armazenamento {0}",
        "communicationEmail": "E-mail de comunicação usado pela empresa",
        "embedded": "Embarcado",
        "partner": "Parceiro",
        "adhesionAgreement": "Termo de Adesão",
        "experimentation": "Experimentação",
        "didactica": "Didactica",
        "distributor": "Distribuidor",
        "inProduction": "Em Produção",
        "blocked": "Bloqueado",
        "canceled": "Cancelado",
        "code": "Código",
        "ordering": "Ordenação",
        "asc": "Crescente",
        "desc": "Decrescente",
        "display": "Exibição",
        "settings": "Configurações",
        "forceUpdatePermission": "Forçar Atualização de Permissão",
        "noPermission": "Sem Permissão",
        "permission": "Permissão | Permissões",
        "clickHere": "Clique aqui",
        "and": "e",
        "selectFile": "Selecionar arquivo",
        "audio": "Áudio|Áudios",
        "file": "Arquivo",
        "objectCode": "Código do {type}",
        "cardImage": "Imagem do Card",
        "summary": "Resumo",
        "fileName": "Nome do Arquivo",
        "fileSize": "Tamanho do Arquivo",
        "scormType": "Tipo Scorm",
        "object": "Objeto | Objetos",
        "diagnostic": "Diagnóstico",
        "reaction": "Reação",
        "learning": "Aprendizagem",
        "applicability": "Aplicabilidade",
        "result": "Resultado",
        assessmentType: "Tipo de Avaliação",
        "order": "Ordem",
        "statement": "Declaração",
        "questionType": "Tipo de Pergunta",
        requiredScore: "Aproveitamento Mínimo (%)",
        "newAttempt": "Permitir Nova Tentativa?",
        "numAttempt": "Número de Tentativas",
        "automatic": "Automático",
        "showTimer": "Adicionar Temporizador na Avaliação",
        "test": "Avaliação | Avaliações",
        assessment: "Avaliação|Avaliações",
        "never": "Nunca",
        "always": "Sempre",
        "whenReject": "Quando reprovado",
        "objective": "Objetiva",
        "multipleChoice": "Múltipla Escolha",
        "trueOrFalse": "Verdadeiro / Falso",
        "scale": "Escala",
        "essay": "Dissertativa",
        "typeQuestions": "Escolha o tipo de pergunta",
        "attachImage": "Anexar Imagem",
        alternative: "Alternativa|Alternativas",
        "customFeedback": "Feedback Personalizado?",
        "feedbackCorrect": "Feedback Quando Correto",
        "feedbackIncorrect": "Feedback Quando Incorreto",
        "feedbackResponding": "Feedback Quando Respondendo à Pergunta",
        "question": "Questão | Questões",
        "synopsis": "Sinopse",
        "mandatorySequence": "Sequência Obrigatória",
        "daysLimit": "Limite de dias para conclusão",
        "mandatoryCompletion": "Conclusão Obrigatória",
        "challenge": "Desafio | Desafios",
        "termOfUse": "Termo de Uso|Termos de Uso",
        "termOfAgreement": "Termo de Aceite|Termos de Aceite",
        "section": "Seção|Seções",
        "learningObjects": "Objetos de Aprendizagem",
        "step": "Etapa | Etapas",
        "sortSections": "Ordenar Seções",
        "associate": "Vincular {title}",
        "communication": "Comunicação | Comunicações",
        "insertLink": "Inserir um link",
        "use": "Usar {field}",
        "segmentation": "Segmentação | Segmentações",
        "publicationPeriod": "Período de Publicação",
        "certificate": "Certificado | Certificados",
        "featuredCommunications": "Comunicações em Destaque",
        "post": "Postagem",
        "displayCommunication": "Sempre exibir comunicações em destaque?",
        "event": "Evento",
        "interval": "Intervalo",
        "daysSubmission": "Dias para Envio",
        "daysNoAccess": "Dias sem Acesso à Plataforma",
        "additionalRecipient": "Destinatário Adicional",
        "subject": "Assunto",
        "draft": "Rascunho",
        "published": "Publicado",
        "copySuperior": "Copiar Superior Imediato no e-mail?",
        "copy": "Copiar",
        "noCopy": "Não copiar",
        "notificationEvent": "Qual é o evento de notificação?",
        "sendingInterval": "Intervalo de Envio de E-mail",
        "days": "Dias",
        "provideEmail": "Informe um email para teste.",
        "templateLayout": "Layout do Template",
        "templatePreview": "Visualização do Template",
        "greetings": "Olá <strong>{name}</strong>, como você está?",
        "undefined": "Indefinido",
        "achievements": "Conquistas",
        "page": "Página | Páginas",
        "classification": "Classificação",
        "typeContent": "Tipo de Conteúdo",
        "daysCompleted": "Dias para Concluir",
        "rule": "Regra | Regras",
        "applicationPeriod": "Período de Aplicação",
        "to": "Para | Até",
        "completeWithin": "Concluir em até",
        "score": "Pontuação",
        "conquest": "Conquista",
        "useMedia": "Utilizada em mídias",
        "useCourses": "Utilizada em cursos",
        "useTrails": "Utilizada em trilhas",
        "useTests": "Utilizada em avaliações",
        "repository": "Depósito | Depósitos",
        "operator": "Operador",
        "or": "Ou",
        "male": "Masculino",
        "female": "Feminino",
        "nonBinary": "Não Binário",
        "debug": "Debug",
        "showGuidelines": "Exibir linhas guias",
        "background": "Fundo",
        "changeBackground": "Alterar imagem de fundo",
        "removeBackground": "Remover imagem de fundo",
        "flipHorizontally": "Inverter horizontalmente",
        "flipVertically": "Inverter verticalmente",
        "element": "Elemento | Elementos",
        "addText": "Adicionar Texto",
        "addImage": "Adicionar Imagem",
        "simulateValues": "Simular valores",
        "preview": "Visualizar {text}",
        "tag": "Tag | Tags",
        "certificateId": "ID do Certificado",
        option: "Opção|Opções",
        optional: "Opcional|Opcionais",
        "moreOptions": "Mais Opções",
        "opacity": "Opacidade | Opacidades",
        "width": "Largura",
        "height": "Altura",
        "align": "Alinhamento | Alinhamentos",
        "layer": "Camada | Camadas",
        "formatting": "Formatação",
        "textColor": "Cor do Texto",
        "font": "Fonte",
        "textSize": "Tamanho do Texto",
        "italic": "Itálico",
        "bold": "Negrito",
        "mine": "Meu",
        "lightTheme": "Tema Claro",
        "darkTheme": "Tema Escuro",
        "pageIcon": "Ícone da Página",
        "corporativeEducation": "Educação Corporativa",
        "interface": "Interface",
        "primaryColor": "Cor Primária",
        "fontColor": "Cor da Fonte",
        "autoFontColor": "Cor da Fonte Automática?",
        "secondColor": "Segunda Cor",
        color: "Cor|Cores",
        backgroundColor: "Cor de Fundo",
        pick: "Escolher",
        left: "Esquerda",
        right: "Direita",
        syllabus: "Conteúdo Programático",
        backOfCertificate: "Verso do Certificado",
        "dateTime": "Data & Hora",
        "username": "Nome de Usuário",
        "revertChanges": "Reverter Alterações",
        "menu": "Menu",
        "for": "para",
        "character": "Caractere | Caracteres",
        "example": "Exemplo",
        "privacyPolicy": "Política de Privacidade",
        "hi": "Oi",
        "default": "Padrão",
        "homePage": "Página Inicial",
        "previousPage": "Página Anterior {text}",
        "table": "Tabela",
        "notRegistered": "Não inscritos",
        "both": "Ambos",
        "automatedNotification": "Notificação automatizada",
        "externalCertificate": "Certificado Externo",
        "person": "Pessoa",
        "started": "Iniciado",
        "transfer": "Transferência",
        "autoPlay": "Auto play",
        "nextActivity": "Próxima atividade em",
        "extension": "Extensão",
        "vocational": "Profissionalizante",
        "academic": "Acadêmico",
        "specialization": "Especialização",
        "describeOthers": "Outros (descreva)",
        "other": "Outro | Outros",
        "copied": "Copiado",
        "copyLink": "Copiar link",
        "dissociate": "Desvincular",
        "reject": "Rejeitar",
        "newEmail": "Novo E-mail",
        "confirmEmail": "Confirme o e-mail",
        "registered": "Inscritos",
        "unnamed": "Sem nome",
        "noUnit": "Sem unidade",
        "setDate": "Definir data",
        "selectAllPage": "Selecionar todos da página",
        "unmarkAllPage": "Desmarcar todos da página",
        "invertSelection": "Inverter seleção da página",
        "tablePage": "{0}/Página",
        "linkLinkedin": "Vincular ao LinkedIn",
        "shareLinkedin": "Compartilhar no LinkedIn",
        "myCertificate": "Meu certificado",
        "externalLink": "Link externo",
        "remaining": "Restante",
        "attempts": "Tentativas",
        "minimumGrade": "Nota mínima",
        "timeSpent": "Tempo gasto",
        "hit": "Acerto | Acertos",
        "miss": "Erro | Erros",
        obtainedScore: "Nota obtida",
        "viewAnswer": "Ver Gabarito",
        "attemptsExhausted": "Tentativas esgotadas",
        "myAnswers": "Minha resposta | Minhas respostas",
        "correctAnswers": "Respostas corretas",
        "theMedia": "A {object} | O {object}",
        "studyPlan": "Plano de Estudo",
        "contract": "Contrato | Contratos",
        "field": "Campo",
        "fieldName": "Nome do Campo",
        "data": "Dados",
        "congratulations": "Parabéns",
        "goTo": "Ir para {0}",
        "conclusion": "Conclusão | Concluído",
        "educationalInstitution": "Instituição de ensino",
        "typeCourse": "Tipo de curso",
        "import": "Importação de {type}",
        "incorrect": "Incorreto | Incorretos",
        "correct": "Correto | Corretos",
        "answerList": "Gabarito",
        "note": "Nota | Notas | Anotação | Anotações",
        "notification": "Notificação | Notificações",
        "notImplemented": "Não implementado",
        "advanced": "Avançado",
        "publicPanel": "Painel Público",
        "replicate": "Replicar Cadastros do Distribuidor",
        "panel": "Painel | Paineis",
        "logo": "Logo | Logos",
        "banner": "Banner | Banners",
        "pageTitleAndPageDescription": "Titulo da Página e Descrição da Página",
        "loginImage": "Imagem de Login",
        "loginBoxPosition": "Posição da Caixa de Login",
        "logoutAddress": "Endereço de Logout",
        "privacyPolicyAddress": "Endereço da Política de Privacidade",
        "standardTermsOfUse": "Termo de Uso Padrão",
        "certificates": "Certificados",
        "standardCertificate": "Certificado Padrão",
        "remove": "Remover",
        "correctOrIncorrect": "Correta ou Incorreta",
        "trueOrFalses": "Verdadeira ou Falso",
        "unitCode": "<strong>Código:</strong> {title}",
        "pt": "Português",
        "en": "Inglês",
        "es": "Espanhol",
        "expirationDate": "Expira em: {date}",
        "reactions": "{count} Reação | {count} Reações",
        "react": "Reagir",
        "reacted": "Reagido",
        "dateExpiration": "Dt. Vencimento",
        "inscriptionDate": "Dt. Inscrição",
        "practice": "Prática",
        "behavior": "Comportamento",
        "linkManager": "Vincular Gestor Avaliador",
        "evaluatorManager": "Gestor Avaliador",
        "linkTargeting": "Vincular Segmentação",
        "microphone": "Microfone",
        "screen": "Tela",
        "camera": "Câmera",
        "uploadType": "Tipo de Upload",
        "typeOfAnswer": "Tipo de Resposta",
        maxScore: "Nota Máxima",
        "courseOrTrail": "Curso/Trilha",
        "availableIn": "Disponível em",
        "heldIn": "Realizado em",
        "ratedIn": "Avalido em",
        "disapproved": "Reprovado",
        "newTry": "Nova Tentativa",
        "behaviorAssessment": "Avaliações de Comportamento | Avaliação de Comportamento",
        reviewAssessment: "Revisar Avaliação",
        reviewing: "Revisando: {title}",
        "closingAssessment": "Encerrando Avaliação",
        "timeUsed": "Tempo Utilizado",
        "partialGrade": "Nota Parcial",
        "landscape": "Paisagem",
        "portrait": "Retrato",
        carousel: "Carrossel|Carrosséis",
        slide: "Slide|Slides",
        "answersCorrect": "Resposta correta",
        "answersIncorrect": "Resposta incorreta",
        "answersPartiallyCorrect": "Resposta parcialmente correta",
    },
    "fileType": {
        "spreedsheet": "Planilha | Planilhas",
        "textDocument": "Documento de Texto | Documentos de Texto",
        "presentation": "Apresentação | Apresentações"
    },
    "placeholder": {
        "select": "Selecionar {field}",
        "email": "Digite seu e-mail",
        "password": "Digite sua senha",
        "newPassword": "Digite sua nova senha",
        "confirmPassword": "Confirme sua senha",
        "uniqueIdentifier": "Informe o identificador único {name}",
        "learnToday": "O que aprenderemos hoje?",
        "search": "Pesquisar {field}",
        "quickSearch": "Pesquisa rápida",
        "searchFuncionality": "Qual funcionalidade você deseja pesquisar?",
        "help": "Ajuda",
        "fullname": "Digite seu Nome Completo",
        "zipCode": "Digite seu CEP",
        "address": "Digite seu Endereço",
        "enter": "Digite {field} | Digite sua {field} | Digite seu {field} | Digite a {field} | Digite o {field}",
        "sectionsAssociate": "Associar seções plantares",
        "communicationsAssociate": "Associar comunicações",
        "clickToAssociate": "Clique para associar {field}",
        "score": "Digite a pontuação entre {0} e {1}",
        "statement": "Digite a declaração da pergunta que será apresentada ao aluno...",
        "feedbackCorrect": "Digite o feedback que será apresentado ao aluno quando ele responder corretamente à pergunta...",
        "feedbackIncorrect": "Digite o feedback que será apresentado ao aluno quando ele responder incorretamente à pergunta...",
        "feedbackResponding": "Digite o feedback que será apresentado ao aluno quando ele responder à pergunta...",
        "postContent": "Conteúdo da postagem",
        "selectMultiple": "Selecione um ou mais {field}",
        "uploadImage": "Envie sua imagem",
        "subject": "Forneça um assunto para o e-mail.",
        "enterPasteScript": "Insira/cole aqui o script adicional",
        "enterPasteScriptContent": "Insira/cole o conteúdo do script",
        "enterPasteScriptName": "Insira o nome/identificador do script (opcional)",
        "enterPasteAdress": "Insira/cole o endereço do script (opcional)",
        "insertLink": "Insira o endereço de redirecionamento do banner. Exemplo: https://www.seusite.com.br",
        "confirm": "Confirme {field}",
        "reason": "Informe o motivo da exclusão",
        "answerHere": "Escreva sua resposta aqui",
        "enterNickname": "Informe o apelido",
        "enterDocument": "Informe o documento",
        "enterTitle": "Informe o titulo",
        "enterId": "Informe o ID",
        "enterDescription": "Informe a descrição",
        "enterObservation": "Informe as observações",
        "enterName": "Informe o nome",
        "enterEmail": "Informe o email",
        "enterContent": "Informe o conteúdo",
        "enterFantasyName": "Informe o nome fantasia",
        "enterAddress2": "Informe o complemento do endereço",
        "enterDistrict": "Informe o bairro",
        enterClassification: "Informe a classificação",
        "enterCpf": "Informe o CPF",
        "enterCnpj": "Informe o CNPJ",
        "enterCpfCnpj": "Informe o CPF/CNPJ",
        "enterForeignDocument": "Informe o documento estrangeiro",
        "enterFullName": "Informe o nome completo",
        "enterCompanyName": "Informe o nome da empresa",
        "enterAlternative": "Informe a alternativa {title}",
        "enterAdditionalRecipient": "Informe o destinatário adicional",
        "enterNumberOfDays": "Informe o número de dias",
        "enterNumberOfUsers": "Informe o número de usuários",
        "enterDiskSpace": "Informe o espaço em disco (GBs)",
        "enterCourseLimit": "Informe a quantia limite de cursos",
        "enterTrailsLimit": "Informe a quantia limite de trilhas",
        "enterDefaultUrl": "Informe a URL padrão",
        "enterCustomUrl": "Informe a URL personalizada",
        "enterSynopsis": "Informe a sinopse",
        "enterCode": "Informe o código",
        "enterSumary": "Informe o resumo",
        "enterObjectCode": "Informe o código do objeto",
        "enterInstitution": "Informe a instituição",
        "enterLinkBanner": "Insira o endereço da web que será redirecionado o banner",
        "numberDays": "Nº de dias",
        "contentSearch": "Busca de conteúdo",
        "suggestions": "Sugira um nova mídia, trilha, curso ou tema que gostaria de ver na plataforma.",
        "noResults": "Não encontrou o que procurava? Envie suas sugestões de conteúdo para ajudarmos a melhorar sua experiência na plataforma!"
    },
    "tooltips": {
        "toggleOrder": "Alternar Ordenação",
        "unreadNotifications": "Notificações não lidas",
        "associateMedias": "Associar vídeos, áudios, imagens, PDFs, Scorm, etc.",
        "associateCourses": "Associar cursos na etapa",
        "associateTests": "Associar avaliações na etapa",
        "synopsis": "Forneça uma breve descrição, deixando claro o que será abordado no(a) {type} ou as partes mais importantes.",
        "detailsDescription": "Aqui você deve fornecer mais detalhes sobre os objetivos do(a) {type} para que o aluno possa visualizar claramente o que será coberto.",
        "inscriptionDate": "Dt. da Inscrição: {date}",
        "profile": {
            "calledBy": "Preenchendo este campo, a plataforma o utilizará em toda a comunicação com você",
            "developSkills": "Com base em seus interesses selecionados, sugeriremos conteúdo relevante para você alavancar seu conhecimento e sua carreira!",
            "personalData": "Os campos de Email e Documento só podem ser alterados pela administração da plataforma ou pelo RH (quando integrado).",
            "corporateData": "Os dados desta seção são alterados pelo sistema de RH (quando integrado) ou gerente de conta e inscrições.",
            "academicData": "Digite aqui os dados relacionados ao seu percurso acadêmico e outros."
        },
        "originFilter": "Para visualizar ou filtrar conteúdo de distribuidores, selecione a opção 'Distribuidor' na coluna 'Origem'.",
        "export": "Habilitado, exportará todos os registros disponíveis. Desabilitado, exportará apenas os registros visíveis na tabela.",
        "contracts": {
            "aliareCode": "Este código é carregado automaticamente quando integrado à Plataforma Aliare.",
            "observation": "Digite aqui os detalhes específicos do contrato necessários para documentar negociações ou outras atividades.",
            "sectionField": "Neste campo, você pode vincular uma ou mais seções criadas pela Plantar e compartilhá-las com o contrato, tornando-as visíveis no aprendizado do cliente.",
            "communicationsField": "Neste campo, você pode vincular uma ou mais comunicações criadas pela Plantar e compartilhá-las com o contrato, tornando-as visíveis no aprendizado do cliente."
        },
        "units": {
            "activeAutomation": "Automação Ativa",
            "childUnit": "Unidades Filhas"
        },
        "departments": {
            "observation": "Você pode fornecer um texto aqui que auxilia os usuários sobre o departamento."
        },
        "jobsTitles": {
            "developSkills": "Ao fornecer competências, a plataforma sugerirá conteúdo que ajuda o usuário a adquirir conhecimentos relacionados a elas."
        },
        "users": {
            "supervisor": "Especifique o líder, gerente ou superior imediato do usuário.",
            "unit": "Especifique a unidade de negócios à qual o usuário está vinculado.",
            "permission": "Permissões definem as rotinas às quais o usuário pode acessar. Na lista, clique no menu Ações (Definir permissão)",
            "receiveEmail": "Ativado, define que os usuários receberam os e-mails das suas inscrições.",
            classification: 'Classificação que determina o painel de dashboard: Aluno, Administrador ou Gestor'
        },
        "repository": {
            "summary": "Forneça um breve contexto sobre o conteúdo. O texto será apresentado ao aluno na criação de cursos, trilhas e locais de consumo.",
            "developSkills": "Que tal vincular competências que este objeto apoiará os alunos? Você pode selecionar uma ou mais competências, e a plataforma (se configurada) pode sugerir conteúdo para alunos ou posições com base nos objetivos de aprendizado do aluno e/ou gerente.",
            "duration": "Esta é a duração que encontramos no seu arquivo de mídia e será exibida ao usuário durante a reprodução. Se desejar, você pode editá-la ajustando o tempo.",
            "disableActions": "Esta ação não pode ser realizada com uma mídia compartilhada.",
            "qrcode": "Para ler esse código, você pode usar um app leitor de QR ou alguns apps de câmera no seu smartphone.",
            "downloadQrcode": "Download do QR Code",
            "shareQrcode": "Compartilhar QR Code"
        },
        "tests": {
            "diagnostic": "Tem como objetivo reunir informações sobre o conhecimento prévio, habilidades e competências dos alunos.",
            "reaction": "Tem como objetivo medir o nível de satisfação dos funcionários com o treinamento e sua percepção geral do processo.",
            "learning": "Tem como objetivo avaliar o conhecimento adquirido a partir do treinamento aplicado.",
            "applicability": "Tem como objetivo avaliar se o aluno aplicou o que aprendeu no processo de treinamento.",
            "result": "Tem como objetivo avaliar os resultados obtidos a partir do treinamento aplicado.",
            "testType": "Com base no tipo escolhido, serão apresentados modelos de pergunta e resposta.",
            "developSkills": "Ao fornecer competências, a plataforma pode sugerir testes que ajudam o usuário a adquirir conhecimentos relacionados a elas.",
            requiredScore: "Forneça a porcentagem mínima de acerto para o aluno ser aprovado no teste.",
            "newAttempt": "Especifique se o usuário pode ter novas tentativas para concluir o teste e as regras que serão aplicadas.",
            "numAttempt": "Especifique quantas tentativas o usuário terá para concluir o teste.",
            "maxQuestions": "Especifique o número máximo de perguntas que serão apresentadas ao usuário. Se houver mais que o número especificado, elas serão carregadas em tentativas subsequentes, se houver.",
            "displayAnswers": "Se marcado, mostra as respostas corretas e incorretas ao usuário após confirmar a opção selecionada.",
            "shuffleQuestions": "Permite embaralhar as perguntas no teste, saindo da ordem de inclusão.",
            "shuffleOptions": "Permite embaralhar as alternativas da pergunta, saindo da ordem de inclusão.",
            "showTimer": "Se ativado, mostra o cronômetro para o usuário no teste.",
            "duration": "Especifique o tempo que o usuário tem para concluir a avaliação, ou deixe em branco para não ter limite de tempo.",
            "typeQuestions": "Especifique o objetivo da sua pergunta escolhendo entre as opções abaixo.",
            "attachImage": "Permite vincular uma imagem para ser apresentada ao usuário durante a avaliação.",
            "scale": "Permite ao aluno escolher um número em uma escala de 1 ao valor selecionado abaixo.",
            "customFeedback": "Permite incluir feedback a ser apresentado ao usuário após a correção da opção respondida.",
            "behavior": "A Avaliação de Comportamento é uma avaliação que acontece posterior à conclusão do curso/trilha. O prazo de envio será definida pelo seu gestor e você será notificado quando estiver disponível.",
            "submittingBehaviorAssessment": "Prazo para envio da Avaliação de Comportamento"
        },
        "courses": {
            "sequence": "Habilitado, define que o usuário precisa seguir a sequência da estrutura definida durante o consumo. Desabilitado, o usuário pode navegar livremente por qualquer conteúdo.",
            "duration": "Forneça a duração estimada em horas e minutos para o curso. Para calcular automaticamente os itens inseridos, basta clicar no botão esquerdo (calculadora).",
            "progress": "Especifique a porcentagem de progresso necessária para a geração do certificado (quando associado) e/ou para alcançar o status de conclusão.",
            "daysLimit": "Prazo máximo (em dias) para um aluno concluir o curso. Se definido como 0, não há controle de limite.",
            "mandatoryCompletion": "Define se o aluno precisa concluir o conteúdo dentro do prazo de conclusão.",
            "certificate": "O certificado pode ser vinculado a um curso. Uma vez inserido aqui, torna-se o padrão para o curso",
            "skills": "Que tal vincular competências que este objeto apoiará os alunos? Você pode selecionar uma ou mais competências, e a plataforma (se configurada) pode sugerir conteúdo para alunos ou posições com base nos objetivos de aprendizado do aluno e/ou gerente.",
            "challenges": "Que tal vincular desafios que este objeto apoiará os alunos? Você pode selecionar um ou mais desafios, e a plataforma (se configurada) pode sugerir conteúdo para alunos ou posições com base nos objetivos de aprendizado do aluno e/ou gerente.",
            "termOfUse": "Um termo de aceite pode ser específico para um curso e, quando associado, será exibido no final do conteúdo.",
            "sections": "Aqui você pode vincular o curso diretamente à seção já registrada. Ao fazer isso, o conteúdo já será apresentado no processo de aprendizado para o aluno."
        },
        "sections": {
            "typeSection": "Escolha o tipo da sua seção.",
            "environment": "A qual ambiente a seção pertence?",
            "useSegmentation": "Escolher vincular uma segmentação remove vínculos anteriores com unidades, departamentos e cargo.",
            "useDepartment": "Ao escolher vincular uma unidade, departamento ou cargo, segmentações anteriores serão desvinculadas automaticamente.",
            "useBoth": "Não é possível vincular segmentação e Departamento, Cargo e Unidade ao mesmo tempo.",
            "period": "Especifique o período de disponibilidade para a seção ou deixe em branco para exibi-la sempre.",
            "move": "Mover para {title}"
        },
        "challenges": {
            "completeWithin": "0 não tem prazo de conclusão"
        },
        "categories": {
            "types": "Tipo que irá definir em qual local a categoria será listada"
        },
        "segmentations": {
            "observation": "Insira suas observações aqui",
            "operator": "Aqui você restringe a utilização da Segmentação para um contexto determinado. Escolha 'OU' para atender o usuário se ele estiver em pelo menos uma das regras. Escolha 'E' para atender o usuário somente se todas as regras forem atendidas.",
            "selectValidate": "É necessário ter pelo menos um vínculo na Segmentação"
        },
        "certificates": {
            "tag": "Essas tags são substituídas automaticamente pelos valores indicados",
            "linkedin": "Aqui você cria uma nova publicação no Linkedin a partir do seu certificado",
            "profile": "Aqui você adiciona um novo certificado ao seu perfil, incluindo novas competências adquiridas, etc.",
            "unableCertificate": "Você ainda não alcançou as condições para receber o certificado",
            "emitCertificate": "Emitir certificado"
        },
        "permissions": {
            "select": "De acordo com o perfil desejado, marque em cada módulo as funcionalidades que os usuários vinculados a este grupo de permissão poderão ter acesso.",
            "validateSelect": "Você deve selecionar pelo menos uma permissão!"
        },
        "customizations": {
            "collapsedMenu": "A imagem será exibida no menu lateral recolhido da plataforma",
            "menuLogin": "A imagem será exibida no menu lateral e na página de login da plataforma",
            "pageIcon": "O ícone da página é geralmente exibido na guia do navegador",
            "previewChanges": "Visualize as alterações em tempo real no sistema"
        },
        "automated": {
            "forUsers": "Ativado, define que a automação será válida para usuários cadastrados a partir da data informada no campo seguinte. Obs: Se marcado, campo data será obrigatório",
            "forAll": "Ativado, define que a automação será válida para todos os usuários cadastrados, independe da data do seu cadastro.",
            "setDate": "Campo Data: Ao informar-lá será considerado para a inscrição, somente os cadastros de usuários maior ou igual à data informada",
            "notifyEmail": "Ativado, define que os usuários receberam os e-mails das suas inscrições."
        },
        "details": {
            "limitDays": "Limite de dias para conclusão",
            "requiredProgress": "Andamento requerido"
        },
        "termsOfUse": {
            "type": "O tipo <strong>Plataforma</strong> poderá ser vinculado aos parâmetros de acesso para que o usuário aceite no primeiro acesso ou sempre que as regras mudarem, já o tipo <strong>Aprendizado</strong> será vinculado a um Curso ou Trilha definindo critérios ou regras de aceite em relação ao conteúdo.",
            "tags": "Para inserir uma tag na estrutura, posicione o mouse no local desejado e clique na tag a ser incluída. Para saber tudo sobre criação de termos de uso acesse o menu ajuda."
        },
        "textDocument": "Documento de texto",
        "presentation": "Apresentação",
        "spreadsheet": "Planilha",
        "other": "Outros",
        "bookmark": "Favorito|Favoritos"
    },
    "tabs": {
        "personalData": "Dados Pessoais",
        "corporateData": "Dados Corporativos",
        "academicData": "Dados Acadêmicos"
    },
    "modals": {
        "profile": "Meu perfil",
        "detailsDashboard": "Detalhes {title}",
        "select": "Selecionar",
        "associate": "Associando {title}",
        "details": "Detalhes",
        "adding": "Adicionando um {title} | Adicionando uma {title}",
        "editing": "Editando {title}",
        "insertImage": "Inserir imagem",
        "dissociate": "Desvincular {title}",
        "associateWith": "Associando {title} nos {type}",
        "inscriptions": "Inscrições {type} {title}",
        "newPassword": "Definir uma nova senha",
        "automated": "Jornadas automatizadas em {type} {title}",
        "previewCertificate": "Prévia do certificado",
        "sectionsOrder": "Definir ordenação das Seções",
        "associations": "Associações com o objeto {title}",
        "associationsCourses": "Associações com o curso {title}",
        "segmentationsUser": "Usuários Vinculados",
        "inscriptionsUser": "Inscrições do Aluno: {title}",
        "alterPermission": "Alterar permissão de {num} usuário selecionado | Alterar permissão de {num} usuários selecionados",
        "addQuestion": "Adicionar pergunta",
        "editQuestion": "Editar pergunta"
    },
    "validation": {
        "required": "Campo obrigatório",
        "invalidEmail": "E-mail inválido",
        "passwordMatch": "As senhas devem coincidir",
        "invalid": "Insira um {field} válido",
        "basicCharacters": "Apenas letras, números e dois caracteres especiais (_-)",
        "max": "O campo não pode exceder {max} caracteres",
        "min": "O campo precisa ter pelo menos {min} caracteres",
        "range": "O valor deve estar entre {0} e {1}",
        "minDuration": "A duração deve ser de pelo menos 1 minuto.",
        "minQuestions": "É necessário inserir pelo menos uma pergunta para o usuário.",
        "nameUsed": "Já existe uma etapa com este nome",
        "selectOption": "Selecione pelo menos uma opção",
        "sameEmail": "O novo e-mail não pode ser igual ao anterior.",
        "equalsEmail": "Os e-mails devem ser iguais.",
        "fileSizeExceeds": "O tamanho do arquivo excede o sugerido para uma boa experiência de navegação. Anexar um arquivo com até {maxSize} {unit} de tamanho.",
        "maxValue": "O valor não pode ser maior que {max}."
    },
    "feedback": {
        "on": "Ativado",
        "off": "Desativado",
        "copyToClipboard": "Copiado para a área de transferência",
        "noResults": "Nenhum resultado encontrado",
        "noResultsSearch": "Não foram encontrados resultados para a pesquisa realizada.",
        "expiredContent": "O conteúdo <strong>{title}</strong>, expirou em <strong>{date}</strong>",
        "emailSent": "E-mail enviado com sucesso!",
        "loading": "Carregando...",
        "updating": "Atualizando...",
        "pleaseWait": "Por favor aguarde",
        "processingRequest": "Processando solicitação",
        "loadingAchievements": "Carregando conquistas...",
        "loadingRanking": "Carregando ranking...",
        "noFound": "Nenhum item encontrado",
        "changeCompany": "Não é possível mudar a empresa do contrato!",
        "formError": "Verifique o(s) erro(s) no formulário",
        "errorLoadObject": "Não foi possível carregar as informações do {object}! Recarregue a página e tente novamente.",
        "successRequest": "Solicitação(ões) enviada(s) com sucesso!",
        "success": "Sucesso",
        "error": "Erro",
        "attention": "Atenção!",
        "failedRequest": "Falha ao enviar a solicitação",
        "errorRequest": "Não foi possível enviar a(s) solicitação(ões)",
        "wait": "Espere!",
        "validationFailed": "Falha na validação",
        "formatInvalid": "Formato não suportado para visualização",
        "failedLoadInfo": "Falha ao carregar informações",
        "processingData": "Processando dados...",
        "await": "Aguarde...",
        "loadingTest": "Carregando informações da avaliação...",
        "somethingWrong": "Opa... Algo deu errado",
        "failedLoadItems": "Erro ao carregar os itens...",
        "loadingObjects": "Carregando {object}",
        "connectionFail": "Parece que não foi possível reestabelecer a comunicação com o serviço. Por favor tente novamente em alguns instantes.",
        "userNotFound": "Não foi possível identificar o usuário, recomendados atualizar a página!",
        "noPermissionResource": "Você não tem permissão para gerenciar este recurso",
        "successRequestPassword": "Solicitação enviada com sucesso!",
        "loadingCustomization": "Aguarde... Carregando configurações do sistema",
        "futureBirthday": "A data de nascimento não pode ser uma data futura",
        "searchPlaceholder": "O que você procura?",
        "searchInitFeedback": "Explore os conteúdos disponíveis na plataforma: busque entre mídias, cursos e trilhas para encontrar o que melhor atende às suas necessidades de aprendizado.",
        "navigate": "Navegar",
        "select": "Selecionar",
        "close": "Fechar",
        "recordedResponse": "Sua resposta foi registrada!",
        "updateAvailable": "Atualização Disponível!",
        "updateAvailableMessage": "Uma nova versão do sistema está disponível! Clique aqui ou recarregue a página para aplicar as atualizações.",
        loginSettingsAccess: {
            title: "Conheça nossa nova tela de login!",
            message: "Estamos fazendo alguns ajustes finais, mas você já pode experimentar a nova interface. Para acessá-la, clique no botão abaixo e veja as novidades.",
        },
        "feedbackQuestion": "Em breve, você receberá um feedback sobre a sua resposta. Agradecemos o seu empenho na realização da prova.",
        "suggestionTitle": "Sugestão de Conteúdo",
        "suggestionSent": "Sugestão enviada com sucesso!",
        "suggestionSentMessage": "Obrigado por sua sugestão! Nós valorizamos seu feedback.",
        cantReact: "Você só pode reagir ao conteúdo após a conclusão."
    },
    "login": {
        "welcome": "Bem-vindo!",
        "forgotPasswordTitle": "Digite seu e-mail de login para que possamos enviar o link de redefinição de senha.",
        "returnLogin": "Voltar para a tela de login",
        "sendEmail": "Enviar",
        "forgotPassword": "Esqueceu sua senha?",
        "helpNeeded": "Precisa de ajuda para fazer login?",
        "talkToUs": "Fale Conosco",
        "rememberMe": "Lembrar de Mim",
        "passwordPlaceholder": "Digite sua senha",
        "emailPlaceholder": "Digite seu e-mail",
        "userFound": "Usuário encontrado...",
        "sessionData": "Registrando dados da sessão.",
        "loginWith": "Continuar com",
        "authenticated": "Usuário autenticado com sucesso!",
        "loadingInformation": "Carregando informações adicionais",
        "accountInformation": "Informações da Conta",
        "userData": "Dados do Usuário",
        "userPermissions": "Permissões do Usuário"
    },
    "recover": {
        "title": "Esqueceu sua senha?",
        "hintTitle": "Digite seu e-mail cadastrado na Plataforma.",
        "hintBody": "Vamos enviar um link para redefinir sua senha.",
        "submited": "Assim que acessar o link enviado para o e-mail <strong>{email}</strong>, você será redirecionado para a página de redefinição de senha.",
        "submitedInstructions": "Você receberá em breve um e-mail com o link para redefinir sua senha."
    },
    "newPassword": {
        "title": "Defina sua nova senha",
        "hintTitle": "Digite sua nova senha.",
        "hintBody": "Agora você pode acessar a Plataforma com sua nova senha.",
        "rulesText": "Sua Nova Senha deve ter",
        "recoverAgain": "Redefinir Senha Novamente",
        "expired": {
            "title": "Token Inválido/Expirado",
            "msg": "O token de ativação é inválido ou não mais válido. Por favor, solicite uma nova recuperação de senha ou entre em contato com o suporte."
        },
        "success": {
            "title": "Senha redefinida com sucesso!",
            "msg": "Agora você pode acessar sua conta novamente."
        },
        "redirectionMsg": "Você será redirecionado para a tela de Acesso à Plataforma em alguns segundos.",
        "rules": {
            "length": "Mínimo de {min} e máximo {max} caracteres",
            "hasLetters": "Caracteres textuais (abc)",
            "case": "Pelo menos uma letra maiúscula",
            "hasNumbers": "Caracteres numéricos (123)",
            "hasSpecialChars": "Caracteres especiais (&$#!)",
            "match": "Ambas as senhas devem coincidir"
        },
        "validation": {
            "minMax": "A senha deve ter entre {min} e {max} caracteres.",
            "letters": "A senha deve conter pelo menos 1 letra.",
            "case": "A senha deve conter pelo menos 1 letra maiúscula.",
            "numbers": "A senha deve conter pelo menos 1 número.",
            "specialChars": "A senha deve conter pelo menos 1 caractere especial."
        }
    },
    "alerts": {
        "youIncredible": "Você é incrível!",
        "noNotifications": "Por enquanto, nenhuma nova notificação. Aproveite seus estudos!",
        "dashboardError": "Ocorreu um erro ao recuperar os dados do painel. Por favor, tente novamente em alguns minutos.",
        "canCreateUsers": "Seu contrato atingiu o número máximo de usuários ativos! Por favor, entre em contato com o suporte.",
        "loadingPartners": "Carregando os parceiros, aguarde um momento.",
        "errorLoadPartners": "Não foi possível carregar os parceiros vinculados à sua conta, tente novamente em alguns instantes.",
        "resubscribe": "Caso clique no botão <strong>Reinscrever</strong>, você perderá seu conteúdo já concluído! <br>Para mais informações, contate seu gestor ou o suporte da plataforma",
        "scrollTermsOfUse": "O botão Aceitar será habilitado quando você rolar o conteúdo até o final.",
        "document": "Neste passo, é necessário um arquivo complementar. Clique no botão abaixo para realizar o download.",
        "errorMediaTitle": "Erro ao buscar informações da midia",
        "errorMediaMessage": "Houve um erro ao tentar carregar informações da midia. Tente fechar e abrir novamente.",
        "termOfUse": "Este termo de uso está vinculado aos parâmetros e sua estrutura não pode ser alterada.",
        "errorBiContent": "Houve um erro ao tentar carregar informações do Painel selecionado. Tente novamente ou verifique se as informações cadastradas do Painel estão corretas"
    },
    "language": {
        "set": "Definir idioma",
        "change": "Mudar idioma"
    },
    "notifications": {
        "activityHistory": "Histórico de Atividades",
        "alertEnable": "Clique no botão abaixo para permitir notificações em seu navegador.",
        "enable": "Habilitar notificações",
        "unsupportedTitle": "Notificações instantâneas desativadas!",
        "unsupportedBody": "Seu navegador não suporta notificações ou elas estão desativadas.",
        "history": "Histórico de Notificações",
        "uploadHistory": "Histórico de Envios",
        "unread": "Apenas não lidas",
        "markSelectionRead": "Marcar selecionados como lido"
    },
    "menu": {
        "learning": "Aprendizado",
        "management": "Gestão",
        "myProgress": "Meu Progresso",
        "dashboard": "Dashboard",
        "myPanel": "Meu Painel",
        "bi": "BI",
        "externalCertificates": "Certificados Externos",
        "accounts": "Contas",
        "companies": "Empresas",
        "contracts": "Contratos",
        "units": "Unidades",
        "departments": "Departamentos",
        "jobsTitles": "Cargos",
        "users": "Usuários",
        "content": "Conteúdo",
        "repository": "Depósito",
        "tests": "Avaliações",
        "courses": "Cursos",
        "trails": "Trilhas",
        "sections": "Seções",
        "manageExternalCertificates": "Gerir Certificados Externos",
        "communications": "Comunicações",
        "communication": "Comunicação",
        "automatedNotifications": "Notificações Automatizadas",
        "gamification": "Gamificação",
        "journey": "Jornada",
        "challenges": "Desafios",
        "settings": "Configurações",
        "customizations": "Personalizações",
        "skills": "Competências",
        "permissions": "Permissões",
        "categories": "Categorias",
        "certificates": "Certificados",
        "termsOfUse": "Termos de Uso",
        "segmentations": "Segmentações",
        "rules": "Regras",
        "myContract": "Meu Contrato",
        "notificationHistory": "Histórico de Notificações",
        "uploadHistory": "Histórico de Uploads",
        "notifications": "Notificações",
        biPanels: "Painel BI|Painéis BI",
        "importHistory": "Histórico de Importações"
    },
    "topbar": {
        profile: "Perfil",
        settings: "Configurações",
        termsAndConditions: "Termos e Condições",
        changeCompany: "Mudar empresa",
        logout: "Sair"
    },
    "mainContent": {
        "sections": {
            "keepLearning": "Continue Aprendendo",
            "suggestedForYou": "Sugeridos Para Você",
            "favorites": "Seus favoritos serão vistos aqui! <br> Para favoritar, basta marcar o ícone no canto superior presente no conteúdo",
            "noFound": "Não localizamos nenhum resultado em <strong>{text}!</strong> O que acha de tentar novos filtros ou mudar de aba?",
            "startLearning": "Que tal começar sua jornada de aprendizado? <strong>Clique aqui</strong> e encontre o conteúdo ideal para você!",
            "emptySection": "Você não tem nenhuma seção ativa.<br>Comece agora mesmo a inserir<br>conteúdos!Clique aqui!<br>"
        },
        "export": {
            "moveColumns": "Mover todas as colunas?",
            "columnsExported": "Colunas a serem exportadas",
            "dragColumns": "Arraste as colunas aqui",
            "exportAll": "Exportar todos os dados?",
            "help": "Arraste todas as colunas disponíveis que você deseja exportar para a área 'Colunas a serem exportadas' à direita.",
            "availableColumns": "Colunas disponíveis",
            "removeColumns": "Remover todas as colunas"
        },
        "units": {
            "addChild": "Adicionar Unidade Filha",
            "helpTitle": "Crie sua estrutura organizacional!",
            "createOther": "Você pode criar outras <strong>unidades</strong> dentro de uma já existente.",
            "justClick": "Basta Clicar",
            "orCreate": "ou criar uma nova estrutura na raiz clicando"
        },
        "users": {
            "import": {
                "alerts": {
                    "initial": {
                        "title": "Orientações para Importação em Lote",
                        "message": "Para importação em lote, o arquivo deve estar no formato .xls ou .xlsx. Para prosseguir com a importação, você precisa baixar o arquivo modelo, preenchê-lo e enviar."
                    },
                    "valid": {
                        "title": "O arquivo foi validado com sucesso!",
                        "message": "Clique em 'importar dados' para concluir o agendamento da importação."
                    },
                    "finish": {
                        "title": "Seus dados foram agendados com sucesso!",
                        "message": "Os dados estão agendados para importação. Por favor, verifique a lista de usuários após 10 minutos para acompanhar o progresso."
                    },
                    "processing": {
                        "title": "Validando seu arquivo!",
                        "message": "Selecione os parâmetros e clique em 'Importar Dados' para concluir."
                    },
                    "failed": {
                        "title": "Não conseguimos validar seu arquivo! Verifique se é do tamanho e formato corretos, e tente novamente.",
                        "message": "Para mais detalhes sobre a importação"
                    }
                },
                "feedback": {
                    "initial": {
                        "title": "Importe seus dados facilmente",
                        "message": "O registro em lote oferece mais conveniência e rapidez para você, permitindo a importação de dados para diferentes pessoas ao mesmo tempo."
                    },
                    "processing": {
                        "title": "Estamos validando seu arquivo...",
                        "message": "Esta etapa é para garantir que tudo esteja correto com o arquivo antes de ser importado."
                    },
                    "failed": {
                        "title": "Resumo da Importação"
                    },
                    "start": {
                        "title": "A importação está em andamento...",
                        "message": "A importação será concluída em breve."
                    }
                },
                "importTemplate": "Não tem o modelo para importar usuários em lote?",
                "download": "Baixar modelo",
                "log": "Baixar o log",
                "dragdrop": "Arraste e solte o arquivo .xls / .xlsx aqui ou"
            },
            "updateEmail": "Atualização do E-mail",
            "triggerEmail": "Dispara e-mail da inscrição"
        },
        "inscriptions": {
            "import": {
                "feedback": {
                    "initial": {
                        "message": "O registro em lote oferece mais conveniência e rapidez para você, permitindo a importação de dados para diferentes inscrições ao mesmo tempo."
                    },
                },
                "importTemplate": "Não tem o modelo para importar inscrições em lote?",
            },
        },
        "repository": {
            "dragDrop": "Selecione ou arraste um arquivo para esta área.<br><br> Considerando a experiência do usuário ao assistir a vídeos, recomendamos que seus arquivos não ultrapassem um tamanho de 1 GB.",
            "generateQr": "Gerar QR Code",
            "insertLink": "Insira o Link"
        },
        "automatedNotifications": {
            "events": {
                "notAccessEnvironment": "Não acessou a plataforma durante um período de dias definido",
                "notStartedCourse": "Não iniciou o curso durante um período de dias definido",
                "notStartedTrail": "Não iniciou a trilha durante um período de dias definido",
                "expireCourse": "Vencimento próximo de curso",
                "expireTrail": "Vencimento próximo de trilha"
            },
            "sendingInterval": {
                "once": "Apenas uma única vez",
                "day": "Uma vez por dia",
                "week": "Uma ver por semana",
                "month": "Uma vez por mês"
            },
            "template": {
                "notAccessEnvironment": "<p>Percebemos que faz um tempo desde a sua última visita à nossa plataforma. Sabemos o quanto é importante manter-se atualizado e em constante aprendizado.</p><p>Estamos aqui para lembrar que temos uma grande variedade de cursos e trilhas disponíveis  para ajudar no seu crescimento profissional.</p><p>Aproveite para explorar novos conteúdos e continuar a sua jornada de aprendizado.</p><p>Esperamos vê-lo novamente em breve!</p>",
                "notStartedCourse": "<p>Queremos incentivá-lo a iniciar o curso ou trilha que está disponível para você em nossa plataforma. Gostaríamos de lembrá-lo(a) de que você recebeu uma inscrição, porêm ela ainda está pendente. Acesse a plataforma e dê o primeiro passo  em seu aprendizado!</p>",
                "expireCourse": "<p>Esperamos que esteja aproveitando o curso ou trilha que está realizando conosco! Gostaríamos de lembrá-lo(a) de que o prazo de vencimento do seu curso ou trilha está se aproximando. Aproveite este momento para concluir o curso ou trilha dentro do prazo estabelecido e continue investindo em seu desenvolvimento profissional. Estamos ansiosos para vê-lo(a) alcançar seus objetivos e esperamos vê-lo(a) novamente em breve!</p>"
            },
            "subject": {
                "notAccessEnvironment": "Sentimos sua falta! Volte para a plataforma e continue aprendendo",
                "notStartedCourse": "Não perca a chance de aprender! Comece seu curso/trilha agora",
                "expireCourse": "Lembrete: Seu curso/trilha está quase no fim"
            },
            "needHelp": "<strong>Precisa de ajuda?</strong> Nosso time de suporte está pronto para te apoiar, basta clicar no ícone de ajuda.",
            "previewFooter": "Este é um e-mail automático enviado por Plantar Educação | www.plantareducacao.com.br",
            "tags": {
                "typeContent": "Tipo do conteúdo",
                "limitDate": "Data de vencimento do curso/trilha",
                "unit": "Nome da unidade de negócio do usuário",
                "department": "Nome do departamento do usuário",
                "jobTitle": "Cargo do usuário",
                "dateTime": "Data e hora do sistema",
            }
        },
        "challenges": {
            "environment": "Em qual ambiente será aplicado o desafio?",
            "typeContent": "Para qual tipo de conteúdo?"
        },
        "certificates": {
            "student": "Nome do aluno",
            "cpf": "CPF do aluno",
            "jobTitle": "Cargo do aluno",
            "content": "Nome do curso/trilha",
            "completed": "Data que o curso/trilha foi finalizado",
            "duration": "Quantidade de horas do curso/trilha",
            "certificateId": "Identificador único do certificado",
            "newContent": "Novo Conteúdo",
            "objectiveCompleted": "<p><strong>Parabéns!</strong> Você completou os objetivos e merece esse certificado!</p><p>Você pode visualizar e baixar seu certificado clicando no botão abaixo!</p>",
            "certificateCompletion": "Certificado de conclusão",
            "sentTestTitle": "Obrigado! Sua avaliação foi recebida!",
            "sentTestMessage": "Sua opinião é muito importante para melhorarmos continuamente.",
            "completedProgress": "Você atingiu o andamento e/ou aproveitamento necessário para este conteúdo",
            "canReturn": "Você pode retornar e revisar sempre que quiser utilizando também a área de",
            "searchContent": "ou buscar novos conteúdos acessando o ambiente",
            "generateNow": "O que acha de gerar seu certificado agora mesmo"
        },
        "customizations": {
            "homePage": "Exibir na Página Inicial",
            "collapsedMenu": "Menu Contraído",
            "menuLogin": "Menu e Login",
            "generalSettings": "Configurações Gerais",
            "defaultCertificate": "Certificado Padrão",
            "logoutAddress": "Endereço de Logout",
            "policyAdress": "Endereço da Política de Privacidade",
            "backgroundImage": "Imagem de Fundo(s)",
            "boxPosition": "Posição da Caixa",
            "logos": "Logos e Favicon",
            "pageTitle": "Título e Descrição da Página",
            "integrations": "Integrações",
            "newScript": "Novo Script",
            "downloadPdf": "Permitir download de documentos PDF",
            "addWatermark": "Adicionar marca d'água aos documentos PDF",
            "menuLogoText": "Insira a imagem para o logotipo do menu interno no formato .JPG ou .PNG. Recomendamos imagens com as dimensões de <strong>90 x 60</strong> pixels. Anexe um arquivo de até <strong>100 kb</strong> de tamanho.",
            "loginLogoText": "Insira a imagem para o login externo no formato .JPG ou .PNG. Recomendamos imagens com as dimensões de <strong>150 x 150</strong> pixels. Anexe um arquivo de até <strong>100 kb</strong> de tamanho.",
            "logo": "Logo <strong>{type}</strong>",
            "pageIconHelp": "Insira a imagem para o ícone da página no formato .PNG para preservar a transparência do fundo. Recomendamos imagens com as dimensões de <strong>48 x 48</strong> pixels. Anexe um arquivo de até <strong>100 kb</strong> de tamanho.",
            "pageIcon": "Ícone da Página <strong>",
            "internalBanners": "Banners Internos",
            "bannerLearning": "Banner de Cabeçalho do Aprendizado",
            "bannerLogin": "Imagem de Fundo da Tela de Login",
            "bannerNewTab": "Abrir link em nova aba?",
            login: {
                title: "Configurações da Página de Login",
                configurations: "Configurações",
                position: "Posição da Caixa de Login",
                newCarousel: "Novo Carrossel",
                mediaUpload: "Upload de Mídia",
                removeCarousel: {
                    title: "Você realmente deseja excluir este item?",
                    message: "Essa ação é irreversível e o item será removido permanentemente. Se preferir, você pode optar por desativá-lo em vez de excluí-lo."
                }
            }
        },
        "cookies": {
            "title": "Consentimento de Cookies",
            "content": "Utilizamos cookies e tecnologias semelhantes para melhorar a sua experiência, de acordo com as nossas",
            "continue": "Ao continuar navegando, você concorda com estas condições."
        },
        "partner": {
            "access": "Qual empresa deseja acessar?",
            "notSeeAgain": "Não desejo ver esta tela novamente!",
            "currentCompany": "Empresa Atual",
            "otherCompanies": "Outras Empresas",
            "loadList": "Aguarde um instante enquanto carregamos as empresas vinculadas à sua conta.",
            "search": "A busca por \"{text}\" não obteve resultados.",
            "differentTerms": "Experimente pesquisar com termos diferentes. Se precisar de assistência, entre em contato com nosso suporte.",
            "loadingOrganization": "Carregando os dados da organização",
            "defaultCompany": "Esta é minha empresa padrão"
        },
        "notAllowed": {
            "restrictedArea": "Área Restrita!",
            "noPermission": "Você não possui as devidas permissões para acessar esta página."
        },
        "notFound": {
            "title": "Página não encontrada!",
            "content": "A página que você tentou entrar talvez não exista ou foi movida."
        },
        "learning": {
            "banner": "Que tal inserir um banner aqui?<br>Clique para acessar as configurações!"
        },
        "classRoom": {
            "exitClassRoom": "Sair da sala de aula",
            "unavailableContent": "Conteúdo indisponível",
            "expired": "O tempo para realização do seu conteúdo expirou no dia: <strong>{date}</strong>",
            "contactNewSubscribe": "Contate seu gestor ou o suporte da plataforma para solicitar uma nova inscrição",
            "testInfo": "Informações sobre avaliação",
            "navigateQuestions": "Navegar pelas questões",
            "remainingQuestions": "Questões restantes",
            "timeOver": "<div>O tempo definido para realização desta avaliação acabou!</div><div>O cálculo para a sua aprovação ou reprovação será baseado nas respostas enviadas.</div>",
            "testTips": "Separamos algumas dicas para você realizar sua avaliação sem problemas",
            "tips": "<li>Fique atento ao tempo de duração caso a avaliação possua um limite de tempo</li><li>Sua resposta só será enviada quando você clicar em Enviar Resposta</li><li>Após enviada a resposta você não conseguirá alterar a alternativa selecionada</li>",
            "reactionTestTips": "<li>Sua resposta só será enviada quando você clicar em Enviar Resposta</li><li>Após enviada a resposta você não conseguirá alterar a alternativa selecionada</li><li>Após finalizado ou sair da avaliação, não poderá entrar e fazer novamente</li>",
            "completedItems": "itens concluídos",
            "approved": "Você foi aprovado",
            "disapproved": "Você não atingiu a nota necessária"
        },
        "externalCertificates": {
            "approved": {
                "title": "Sua solicitação foi aprovada",
                "message": "Seu curso já foi aprovado e registrado"
            },
            "denied": {
                "title": "Sua solicitação foi negada",
                "denyPosition": "O certificado não é valido para a posição ocupada",
                "denyInvalid": "O certificado informado é inválido",
                "denyIllegible": "O certificado informado é ilegível",
                "denyOther": "O certificado informado foi negado, consulte o motivo abaixo",
                "help": "Você pode tentar editar o certificado e enviar novamente para aprovação"
            },
            "pending": {
                "title": "Pendente",
                "message": "Aguardando validação e aprovação"
            },
            "incompatibleRole": "Incompatível com a função",
            "invalidCertificate": "Certificado inválido",
            "illegibleCertificate": "Certificado ilegível",
            "approvalRequest": "Solicitação de aprovação: {title}",
            "denyReason": "Informe o motivo da negação",
            "addCertificate": "Adicione seu certificado",
            "dropFile": "<strong>Localize seu arquivo ou arraste e solte aqui</strong> para que seja enviado. Ele dever estar em formato <strong>png</strong>, <strong>jpg</strong> ou <strong>pdf</strong>, e deve ter no <strong>máximo 2 Mb</strong>"
        },
        "automated": {
            "forUsers": "Para usuários criados a partir de data definida",
            "forAll": "Para todos os usuários",
            "notifyEmail": "Dispara e-mail da inscrição"
        },
        "termsOfUse": {
            "structure": "Estrutura do Termo de Uso",
            "user": "Nome do usuário Logado",
            "unit": "Nome da unidade de negócio do usuário logado",
            "department": "Nome do departamento do usuário logado",
            "jobTitle": "Cargo do usuário logado",
            "dateTime": "Data e hora do sistema",
            "date": "Data do sistema",
            "hour": "Hora do sistema",
            "defaultContent": "Informe o termo de uso, que será apresentado para os usuários na Plataforma."
        },
        "notes": {
            "title": "Minhas anotações",
            "view": "Visualizar Minhas Anotações",
            "insert": "Inserir Minha anotação"
        }
    },
    "column": {
        title: "Título",
        noTitle: "Sem Título",
        "type": "Tipo",
        "situation": "Situação",
        "status": "Status",
        "expired": "Expirado",
        "required": "Obrigatório",
        "origin": "Origem",
        "subscribedAt": "Inscrito em",
        "completedAt": "Concluído em",
        "student": "Aluno",
        "institution": "Instituição",
        "createdAt": "Criado em",
        "requestStatus": "Status da Solicitação",
        "description": "Descrição",
        "workload": "Carga Horária",
        "duration": "Duração",
        "startDate": "Dt. de Início",
        "endDate": "Dt. de Término",
        "certificateUrl": "URL do Certificado",
        "credentialCode": "Código da Credencial",
        "credentialUrl": "Link da Credencial",
        "identifier": "Identificador",
        "inscriptionOrigin": "Origem da Inscrição",
        "name": "Nome",
        "onGoing": "Em Andamento",
        "trainingHours": "Horas de Treinamento",
        "access": "Acesso | Acessos",
        "timeAccess": "Data e Hora de Acesso",
        "consumedContent": "Conteúdo Consumido",
        "userAccess": "Acesso do Usuário | Acessos do Usuário",
        "numAccess": "Quantidade de Acessos | Quantidade de Acessos",
        "lastAccess": "Último Acesso | Últimos Acessos",
        "completedDate": "Dt. de Conclusão",
        "contentName": "Nome do Conteúdo",
        "inscriptionDate": "Dt. da Inscrição | Dt. das Inscrições",
        "consumption": "Consumo",
        "unit": "Unidade | Unidades",
        "phone": "Número de Telefone",
        "accountId": "ID da Conta",
        "classification": "Classificação do Cliente",
        "contractDate": "Data do Contrato",
        "qtyUsers": "Quantidade de Usuários",
        "diskSpace": "Espaço em Disco {0}",
        "courseLimit": "Limite de Cursos",
        "trailLimit": "Limite de Trilhas",
        "defaultUrl": "URL Padrão",
        "communicationEmail": "Email de Comunicação",
        "contactPersonName": "Nome da Pessoa de Contato",
        "contactPersonEmail": "Email da Pessoa de Contato",
        "contactPersonPhone": "Telefone da Pessoa de Contato",
        "companyType": "Tipo de Empresa",
        "managerName": "Nome do Gerente",
        "managerDocument": "Documento do Gerente",
        "managerEmail": "Email do Gerente",
        "observation": "Observação",
        "customUrl": "URL Personalizada",
        "parentUnit": "Unidade Pai",
        "fileType": "Tipo de Arquivo",
        "score": "Nota de Aprovação",
        "showTimer": "Exibir Cronômetro",
        "canRetry": "Permitir Tentativas",
        "maxRetries": "Número Máximo de Tentativas",
        "maxQuestions": "Número Máximo de Perguntas",
        "shuffleQuestions": "Embaralhar Perguntas",
        "shuffleOptions": "Embaralhar Alternativas",
        "displayAnswers": "Exibir Respostas Corretas e Incorretas",
        "coverLink": "Link da Capa",
        "environment": "Ambiente",
        "sharedSection": "Seção Compartilhada",
        "bannerLink": "Link do Banner",
        "redirectLink": "Link de Redirecionamento",
        "initialPubDate": "Data de Publicação Inicial",
        "finalPubDate": "Data de Publicação Final",
        "period": "Período",
        "alwaysDisplay": "Sempre Exibir",
        "supervisorCopied": "Supervisor copiado",
        "version": "Versão",
        "registrationDate": "Dt. de Cadastro",
        "justification": "Justificativa",
        "product": "Produto | Produtos",
        "inscription": "Inscrição | Inscrições",
        "dateDispatch": "Data de envio"
    },
    "months": {
        "jan": "Janeiro",
        "feb": "Fevereiro",
        "mar": "Março",
        "apr": "Abril",
        "may": "Maio",
        "jun": "Junho",
        "jul": "Julho",
        "aug": "Agosto",
        "sep": "Setembro",
        "oct": "Outubro",
        "nov": "Novembro",
        "dec": "Dezembro"
    },
    "gamification": {
        "title": "Acompanhe sua jornada",
        "points": "Pontos",
        "ranking": "Classificação Geral",
        "myJourney": "Minha Jornada",
        "conquered": "Conquistado em",
        "latestAchievements": "Últimas Conquistas",
        "status": {
            "error": "Não foi possível recuperar a lista de conquistas.",
            "emptyData": "Não há dados para exibir ainda."
        },
        "fullJourney": "Minha jornada completa",
        "earnMorePoints": "Ganhe mais pontos",
        "place": "{position}º lugar",
        "history": "Histórico de conquistas",
        "moorningSun": "Bom dia para o sol",
        "nightMoon": "Boa noite para a lua",
        "nightStars": "Boa noite para as estrelas",
        "won": "Você ganhou!",
        "getPoints": "Obter pontos",
        "achievementReceived": "Confira essa conquista que recebi!",
        "overallRanking": "Classificação Geral",
        "unitRanking": "Classificação na Unidade"
    },
    "helpers": {
        "profile": "Aqui você pode definir algumas regras de segmentação de conteúdo por habilidades, atualizar seus dados pessoais e profissionais, além de escolher aquela foto especial para o seu perfil (em formato .JPG ou .PNG, com <strong>200 x 200</strong> pixels). Você também pode anexar um arquivo de até <strong>1 MB</strong> de tamanho.",
        "repository": "Aqui, você pode inserir objetos de aprendizagem no formato de Link Externo, que fornece acesso a conteúdos de vídeo de plataformas como Vimeo, Youtube, entre outros links, incluindo acesso a salas de reunião no Teams, Zoom e Google Meet.",
        "cardImage": "Insira a imagem do card para o objeto nos formatos .JPEG, .JPG, .JFIF, .WEBP, .GIF ou .PNG.",
        "recommended": "Recomendamos imagens com <strong>{dimension}</strong> pixels. Anexe um arquivo com um tamanho de até <strong>{size}</strong>.",
        "objectDetails": "Forneça detalhes para o objeto de aprendizagem.",
        "associateQuestions": "Agora vamos associar as perguntas!",
        "structureQuestions": "Aqui, você pode definir a estrutura das suas perguntas e vinculá-las à avaliação.",
        "courseDetails": "Forneça detalhes do curso!",
        "associateObjects": "Agora, vamos associar objetos ao curso.",
        "createSteps": "Que tal criar as etapas para a sua trilha?",
        "structureObject": "Aqui, você estruturará seu curso com objetos já inseridos no repositório e materiais adicionais para os alunos.",
        "stepsCourse": "Nesta etapa, você pode estabelecer as principais regras para o consumo, segmentação e distribuição de conteúdo. Que tal acessar o menu de <strong>Ajuda</strong> e aprender tudo sobre cada parâmetro?",
        "stepsTrail": "Nas etapas, você poderá associar objetos de aprendizagem, cursos e materiais complementares",
        "sectionCommunication": "Insira a imagem para a área de seção do aluno nos formatos .JPG ou .PNG. Recomendamos imagens com dimensões de <strong>1152 x 335</strong> pixels. Anexe um arquivo com tamanho máximo de <strong>3 MB</strong>.",
        "associateContent": "Agora, vamos associar o conteúdo!",
        "associateStructure": "Aqui, você define a estrutura da sua seção adicionando cursos ou trilhas para o acesso dos alunos.",
        "mainRules": "Nesta etapa, você pode estabelecer as principais regras para o consumo, segmentação e distribuição de conteúdo",
        "dontWorry": "Não se preocupe em preencher os filtros; quando deixados em branco, eles mostrarão tudo!",
        "postImage": "<strong>Localize seu arquivo ou arraste e solte aqui</strong> para que seja enviado. Ele dever estar em formato <strong>png</strong> ou <strong>jpg</strong>, e deve ter no <strong>máximo 2 Mb e 800x600 pixels.</strong>",
        "associateRules": "Agora vamos associar regras ao seu desafio.",
        "structureAssocaiteRules": "Aqui você criará a estrutura dos seus desafios usando regras existentes e, em seguida, os vinculará a cursos e trilhas.",
        "challengesGeneralRules": "Nesta etapa, você pode definir as principais regras para os desafios e vinculá-los a cursos e trilhas. Dúvidas? Basta acessar o menu Ajuda e saber tudo sobre cada parâmetro!",
        "bannerLearning": "Insira a imagem para a área interna do aluno no formato .JPG ou .PNG. Recomendamos imagens com as dimensões de <strong>1156 x 384</strong> pixels. Anexe um arquivo com até <strong>3 MB</strong> de tamanho.",
        bannerLogin: "Insira a imagem para a área externa de login do usuário no formato .JPG ou .PNG. Recomendamos imagens com as dimensões de {dimensions} pixels. Anexe um arquivo com até {fileSize} de tamanho.",
        "readQrcode": "Para ler esse código, você pode usar um app leitor de QR ou alguns apps de câmera no seu smartphone.",
        "automatedTitle": "Agora vamos criar as jornadas para seus {object} | Agora vamos criar as jornadas para suas {object}",
        "automatedDescription": "Aqui você poderá criar jornadas automatizadas para as inscrições de seus usuários. Que tal clicar no botão Associar e começar agora mesmo?",
        "automatedRules": "Nesta etapa você pode definir as principais regras para sua automação. Que tal acessar o menu Ajuda e saber tudo sobre cada parâmetro?",
        "stageNoItems": "Essa etapa não possui itens vinculados",
        "notAvailable": "que está tentando acessar não está mais disponível para visualização, por favor entre em contato com o administrador",
        "noNotes": "Aqui você pode visualizar, editar e excluir notas de suas aulas. Elas o ajudarão a retornar rapidamente a momentos interessantes ou desafiadores mais tarde.",
        "hideSystemChat": "Ocultar chat de ajuda da Plantar Educação",
        "hideSystemChatTT": "Exibe o chat de ajuda da plantar ao clicar no icone de ajuda no menu superior, ou no texto na tela de login.",
    },
    "noContent": {
        "companies": {
            "title": "O primeiro passo é criar as empresas!",
            "message": "Você precisa criar empresas (indivíduos ou pessoas jurídicas) que serão incluídas na sequência dos seus contratos, entendido. Clique no botão abaixo e comece a inserir registros.",
            "buttonText": "Nova Empresa"
        },
        "contracts": {
            "title": "Vamos criar seus Contratos?!",
            "message": "Você poderá criar e acompanhar seus contratos individualmente por cliente: consumo, módulos contratados e fornecer suporte para processos do cliente. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo Contrato"
        },
        "units": {
            "title": "Vamos criar sua estrutura organizacional?",
            "message": "Você pode construir a estrutura da sua unidade com até 5 níveis. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Unidade"
        },
        "departments": {
            "title": "Vamos criar seus departamentos?",
            "message": "Aqui, você pode criar departamentos para vincular aos usuários, facilitando a gestão. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo Departamento"
        },
        "jobsTitles": {
            "title": "Vamos criar seus cargos?",
            "message": "Aqui, você pode criar cargos para vincular aos usuários, facilitando a gestão. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo Cargo"
        },
        "users": {
            "title": "Vamos criar usuários e acessos?",
            "message": "Aqui, você pode criar seus usuários e definir padrões de acesso. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo Usuário"
        },
        "repository": {
            "title": "Vamos criar seus objetos?",
            "message": "Que tal manter todos os seus objetos de aprendizagem em um só lugar? Aqui, você pode categorizá-los por tipos, etc., e usá-los posteriormente associando-os a cursos e trilhas. Clique no botão abaixo e comece a adicionar objetos.",
            "buttonText": "Novo Objeto"
        },
        "tests": {
            "title": "Vamos criar seus testes?",
            "message": "Aqui, você pode criar testes, vinculá-los a cursos e trilhas, e até direcioná-los ao público desejado. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo Teste"
        },
        "courses": {
            "title": "Vamos criar seus cursos?",
            "message": "Agora que você estruturou objetivos de aprendizagem no repositório, pode começar a criar seus cursos rapidamente. Que tal começar agora mesmo!",
            "buttonText": "Novo Curso"
        },
        "trails": {
            "title": "Vamos criar suas trilhas?",
            "message": "Inicie sua trilha inserindo informações gerais, em seguida, forneça uma sinopse. Em seguida, você pode criar etapas e associar objetos de aprendizagem. Que tal começar agora mesmo?",
            "buttonText": "Nova Trilha"
        },
        "sections": {
            "title": "Vamos criar suas Seções?",
            "message": "Aqui você pode criar Seções, vincular cursos, caminhos ou comunicação e até direcioná-los ao público desejado. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Seção"
        },
        "manageCertificates": {
            "title": "Vamos gerenciar Certificados Externos?",
            "message": "Aqui, você pode aprovar ou negar solicitações de Certificados Externos enviadas por usuários da plataforma."
        },
        "communication": {
            "title": "Vamos criar suas comunicações?",
            "message": "Aqui você pode criar comunicações em destaque, facilitando a comunicação com os usuários. O que acha de clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Comunicação"
        },
        "notifications": {
            "title": "Vamos criar suas notificações?",
            "message": "Aqui você pode criar notificações automatizadas, que serão enviadas com base nos eventos definidos ao cadastrar. O que acha de clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Notificação"
        },
        "challenges": {
            "title": "Vamos criar seus desafios?",
            "message": "Aqui você pode utilizar as regras disponíveis para criar seus próprios desafios e direcionar aos cursos e trilhas. O que acha de clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo Desafio"
        },
        "skills": {
            "title": "Vamos criar suas competências?",
            "message": "Aqui você pode criar competências para vincular na gestão e em conteúdos. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Competência"
        },
        "categories": {
            "title": "Vamos criar suas categorias?",
            "message": "Aqui você pode criar categorias para vincular ao depósito, cursos e trilhas, facilitando a gestão. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Categoria"
        },
        "segmentations": {
            "title": "Vamos criar suas segmentações?",
            "message": "Aqui você pode criar suas segmentações para vincular aos usuários, facilitando a gestão. Que tal clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Nova Segmentação"
        },
        "certificates": {
            "title": "Vamos criar seus certificados?",
            "message": "Que tal criar certificados personalizados para seus cursos e trilhas? Clique no botão abaixo e comece agora mesmo!",
            "buttonText": "Novo Certificado"
        },
        "permissions": {
            "title": "Você pode criar Grupos de Permissões!",
            "message": "Aqui você pode criar seus próprios perfis de acesso, definindo quais rotinas seus usuários podem acessar, criando grupos de permissões. Que tal clicar aqui e começar agora mesmo?",
            "buttonText": "Novo Grupo de Permissão"
        },
        "customizations": {
            "title": "Vamos personalizar o seu ambiente?",
            "message": "Aqui você pode configurar o seu ambiente. Que tal dar a ele a sua identidade visual, incluindo banners, cores, logotipo, entre outros?",
            "buttonText": "Personalizar Ambiente"
        },
        "termsOfUse": {
            "title": "Vamos criar os seus termos de uso?!",
            "message": "Aqui você pode criar condições de uso e vincular à plataforma, cursos ou trilhas. O que acha de clicar no botão abaixo e começar agora mesmo ?",
            "buttonText": "Novo termo de uso"
        },
        "externalCertificates": {
            "title": "Vamos criar seus Certificados Externos?",
            "message": "Aqui você pode lançar seus certificados emitidos em outras plataforma. O que acha de clicar no botão abaixo e começar agora mesmo?",
            "buttonText": "Novo certificado externo"
        }
    },
    "dialogs": {
        "ops": "ops!",
        "confirmation": "Confirmação!",
        "setClientMode": {
            "title": "Mudança de Ambiente!",
            "message": "<p>Você está prestes a acessar o ambiente do cliente para o contrato selecionado.</p> Atenção! Todas as ações (mudanças de layout, cadastros, manutenção, etc.) afetarão o ambiente de produção do cliente: <strong>{companyName}</strong>. <p>Você tem certeza de que deseja continuar?</p>"
        },
        "delete": {
            "title": "Atenção",
            "message": "<p>Você está prestes a remover {type}: <strong>{name}</strong></p><p>Essa ação não pode ser desfeita!</p>"
        },
        "recoverPassword": {
            "usersWarningRequest": "Não foi possível enviar pedido(s) para {0} usuário(s)",
            "usersErrorRequest": "Não foi possível enviar pedido(s) para <strong>{0}</strong> usuário(s): <strong>{1}</strong>."
        },
        "removePermissions": {
            "title": "Remover permissões",
            "message": "Deseja remover as permissões do usuário selecionado? | Deseja remover as permissões dos usuários selecionados?"
        },
        "recoveryPassword": {
            "message": "Enviando pedido(s) de recuperação de senha..."
        },
        "executeManualNotification": {
            "title": "Atenção",
            "message": "<p>Você está prestes a executar manualmente o fluxo de notificação. Isso enviará e-mails para os alunos afetados.</p></br><p>Tem certeza de que deseja prosseguir?</p>"
        },
        "tests": {
            "moreThanTrue": "<p>Há mais de uma opção marcada como verdadeira. Se você alterar o tipo de pergunta para <strong>objetiva</strong>, todas as opções serão marcadas como 'falsas'.<p><p>Você tem certeza de que deseja continuar?</p>",
            "removeQuestion": "Você realmente deseja excluir a pergunta indicada?",
            "removeSkills": "Você realmente deseja remover <strong>{name}</strong>?"
        },
        "sections": {
            "changeSegmentation": "<p>Esta seção já tem segmentações definidas. Continuar removerá esses links.</p><p>Você deseja continuar?</p>",
            "changeDepartment": "<p>Esta seção já tem uma unidade, departamento ou cargo definido. Continuar removerá esses links.</p><p>Você deseja continuar?</p>"
        },
        "challenges": {
            "deleteRule": "Você realmente deseja excluir a regra indicada?"
        },
        "certificates": {
            "remove": "Você realmente deseja remover este elemento?"
        },
        "customizations": {
            "changeTermOfUse": "<p>Você alterou os termos de uso padrão, então todos os alunos serão notificados em sua próxima entrada para aceitar os novos termos.</p><p>Deseja continuar?</p>",
            "removeBanner": "<p>Tem certeza de que deseja remover o banner? <strong>Essa ação não pode ser desfeita</strong>!</p><p>No entanto, se desejar, você pode inserir um ou mais banners usando esta rotina.</p>",
            "removeBgLogin": "<p>Esta imagem de fundo parecia legal :(.</p><p>Posso realmente dizer tchau para ela?...<p><strong>Aperte 'confirmar', e a imagem vai embora</strong>.</p>"
        },
        "partner": {
            "title": "Definir como empresa padrão",
            "message": "<p>Você realmente deseja definir a empresa: <strong>{name}</strong> como padrão?</p>"
        },
        "externalCertificates": {
            "title": "Aprovar certificado externo?",
            "message": "<p>Você deseja aprovar esse curso? o aluno será notificado imediatamente</p>"
        },
        "inscriptions": {
            "removeMoreThanOne": "Deseja cancelar <strong>{count}</strong> inscrições selecionadas?",
            "removeOne": "Deseja cancelar a inscrição do aluno selecionado?"
        },
        "automated": {
            "inscriptions": "<p>Caso possua inscrição de alunos vinculado a este curso ou trilha, a mesma será cancelada ao confirmar.</p>Deseja continuar?<p>"
        },
        "repository": {
            "fileExists": "Já existe um arquivo em anexo, deseja prosseguir? (Se sim, o arquivo será desanexado)",
            "linkExists": "Já existe um link inserido, deseja prosseguir? (Se sim, o link será removido)",
            "removeFile": "Deseja realmente remover a mídia atual?"
        }
    },
    "toasts": {
        "failedLoadingMap": "Não foi possivel carregar o mapa de estados! Recarregue a página e tente novamente",
        "invalidFile": "Formato de arquivo inválido",
        "acceptFiles": "Os formatos aceitos são {0}",
        "updateSuccess": "Registro atualizado com sucesso",
        "createSuccess": "Registro cadastrado com sucesso",
        "removeSuccess": "Registro removido com sucesso",
        "sentSuccess": "Registro enviado com sucesso",
        "contactManager": "Entre em contato com seu gestor",
        "removePermissions": {
            "sending": "Removendo permissões",
            "successTitle": "Permissões removidas com sucesso!",
            "successMessage": "As permissões foram removidas"
        },
        "repository": {
            "objectNoFile": "Não é possível salvar um objeto sem um arquivo!",
            "objectNoLink": "Não é possível salvar um objeto sem inserir um link válido!",
            "scormType": "Não é possível salvar um Scorm sem especificar seu tipo!",
            "cancelTransfer": "Cancelada pelo usuário",
            "uploadCover": "da imagem de capa",
            "invalidQrcode": "Link do arquivo inválido para gerar QRCode!"
        },
        "tests": {
            "trueOrFalse": "É possível inserir apenas duas opções em perguntas Verdadeiro/Falso.",
            "noDescription": "Existem opções sem descrições!",
            "oneTrue": "É necessário inserir pelo menos uma opção correta!",
            "addOption": "É necessário inserir uma opção",
            "notSave": "A avaliação não pode ser salva sem nenhuma pergunta!"
        },
        "communication": {
            "requiredPost": "É necessário inserir uma postagem!",
            "requiredTitle": "É necessário inserir um título ou imagem na postagem!"
        },
        "challenges": {
            "addOption": "É necessário associar um item para avançar para a próxima etapa!",
            "points": "A pontuação deve ser maior que zero!",
            "itemToCreate": "É necessário associar um item para criar o desafio!"
        },
        "skills": {
            "observation": "Forneça um resumo sobre a competência que está incluindo."
        },
        "login": {
            "errorLoadingInfo": "Erro ao carregar informações adicionais"
        },
        "sections": {
            "contractLinked": "Contratos vinculados com sucesso!",
            "sortingSaved": "Ordenação salva com sucesso!"
        },
        "users": {
            "updatedEmail": "E-mail atualizado com sucesso!",
            "defaultAccount": "Conta padrão definida com sucesso!",
            "accessValidated": "Seu acesso foi validado!",
            "resetPassword": "Senha redefinida!",
            "welcomeAliare": "Seja bem vindo à Aliare",
            "updatedPassword": "Sua senha foi atualizada com sucesso!"
        },
        "inscriptions": {
            "subscribe": "{0} alunos inscritos com sucesso!",
            "unsubscribe": "{0} inscrições canceladas com sucesso!"
        },
        "system": {
            "saveSuccess": "Parâmetros cadastrados com sucesso"
        },
        "classRoom": {
            "giveUp": "<p>Ao sair da avaliação você perderá o progresso em andamento. Se sua avaliação possuir apenas uma tentativa será considerado como reprovado nesta avaliação.</p>",
            "looseInProgress": "<p>Ao sair da avaliação você perderá o progresso em andamento. Se sua avaliação possuir apenas uma tentativa será considerado <strong>reprovado</strong> nesta avaliação.</p> Deseja continuar?",
            "notReturn": "<p>Ao sair da avaliação você não poderá retornar para realizá-la. Se você preencheu apenas parte das alternativas, as respostas serão desconsideradas.</p>Deseja continuar?",
            "notAnswerReaction": "<p></p>Não deseja responder a avaliação de reação? <strong>Não tem problema! Ao clicar em confirmar marcaremos essa avaliação como concluída. Combinado?</strong></p>"
        },
        "externalCertificates": {
            "selectJustification": "É necessário selecionar uma justificativa!"
        },
        "dashboard": {
            "generatingErrorTitle": "Erro ao gerar certificado",
            "generatingErrorMessage": "Não foi possível gerar o certificado, tente novamente mais tarde"
        },
        "details": {
            "registrationSuccess": "Inscrição realizada com sucesso!",
            "registrationCanceled": "Inscrição cancelada com sucesso!",
            "favoriteSuccess": "Item adicionado aos favoritos com sucesso!",
            "favoriteCanceled": "Item removido dos favoritos com sucesso!",
            "reactionSuccess": "Reação adicionada com sucesso",
            "reactionCanceled": "Reação removida com sucesso"
        }
    },
    "steps": {
        "basicData": "Dados Básicos",
        "offer": "Oferta",
        "settings": "Configurações",
        "contractManager": "Gerir Contratos",
        "generalRules": "Regras Gerais",
        "generalData": "Dados Gerais",
        "associateObjects": "Associar Objetos"
    },
    "errors": {
        "notSupportStreaming": "Suporte a streaming não é suportado pelo navegador"
    },
    "repository": {
        "title": "Depósito",
        "label": {
            "classic": "Depósito Clássico",
            "modern": "Depósito Moderno",
            "displayDuration": "Exibir Duração",
            "displayCode": "Exibir Código",
            "displayCreatedAt": "Exibir Dt. Criação",
            "displayOrigin": "Exibir Origem",
            "displayExpired": "Exibir Expirado",
            "displayRequired": "Exibir Obrigatório",
            "subtitle": "Legenda|Legendas",
            "chapter": "Capítulo|Capítulos",
            "clickToVisit": "Clique aqui para visitar o link",
            "unavailable": "Indisponível"
        },
        "help": {
            "supportedFilesTitle": "Mídias e formatos suportados!",
            "supportedFilesMessage": "É possível inserir vários tipos de objetos de aprendizado como: Vídeo, PDF, Ýudio, Imagem, Documento e Scorm.",
            "supportedLinksTitle": "Formatos de links suportados!",
            "supportedLinksMessage": "É possível inserir links de plataformas como Vimeo, Youtube, entre outros, incluindo acesso a salas de reunião no Teams, Zoom e Google Meet.",
            "unavailable": "Para mais informações, contate seu gestor ou o suporte da plataforma.",
            "cantUploadSubtitlesYet": "Só é possível adicionar legendas após o envio do video e pré-processamento do mesmo.",
            "cantUploadChaptersYet": "Só é possível adicionar capítulos após o envio do video e pré-processamento do mesmo.",
            "chaptersIntroduction": "Os capítulos são exibidos na linha do tempo do vídeo e permitem que os espectadores naveguem com mais facilidade.",
            "noChaptersAddedYet": "Nenhum capítulo adicionado ainda"
        },
        "feedback": {
            "chapterSLE": "O início do capítulo deve ser menor que o final.",
            "chapterEGV": "O final do capítulo não pode ser maior que a duração do vídeo.",
            "chapterOverlap": "Um capítulo não pode se sobrepor a outro capítulo."
        },
        "button": {
            "generateQrCode": "Gerar QRCode"
        },
        "form": {
            "objectType": "Tipo de objeto",
            "objectDuration": "Duração do objeto",
            "durationAutoDetect": "Detectar automaticamente",
            "create": "Criar Objeto de Aprendizado",
            "edit": "Editar Objeto de Aprendizado",
            "downloadable": "Permitir Download",
            "selectScormType": "Selecione o tipo de Scorm",
            "selectCategory": "Selecione a categoria",
            "watermark": {
                "title": "Marca d'água",
                "enable": "Habilitar Marca d'água",
                "userName": "Nome do usuário",
                "userEmail": "E-mail do usuário",
                "company": "Empresa",
                "unit": "Unidade de Negócio",
                "department": "Departamento",
                "position": "Cargo",
                "dateTime": "Data & Hora",
                "userIp": "IP do Usuário"
            },
            "chapter": {
                "start": "Início do Capítulo",
                "end": "Fim do Capítulo",
                "title": "Título do Capítulo"
            }
        }
    },
    "permissionGroup": {
        "form": {
            "titleAdd": "Adicionar Grupo de Permissões",
            "titleEdit": "Editar - {name}",
            "name": "Nome do Grupo de Permissões",
            "namePlaceholder": "Informe o nome do grupo de permissões",
            "hint1": "Selecione todas as funcionalidades que serão disponibilizadas para os usuários deste grupo.",
            "selectFunctionality": "Selecione as funcionalidades"
        }
    },
    "versionManager": {
        "newVersion": "Nova Versão",
        "newAvailable": "Nova versão disponível",
        "newInstructions": "Clique no botão 'Atualizar' ou recarregue a página para aplicar as atualizações.",
        "update": "Atualizar"
    },
    role: {
        all: "Todos",
        admin: "Administrador",
        manager: "Gestor",
        student: "Aluno"
    },
    dashboard: {
        splash: {
            title: "Explore seus Dashboards!",
            description: "Acesse os painéis disponíveis na barra superior e visualize dados essenciais para acompanhar o desempenho e tomar decisões estratégicas.",
        }
    },
    assessmentGiveupResult: {
        title: 'Desistência registrada!',
        message: 'Você pode iniciar uma nova avaliação se ainda houver tentativas disponíveis.'
    },
    // PT-BR
    contract: {
        label: {
            enableAAD: 'Permitir autenticação SSO por AAD',
            AAD: {
                id: "ID do Cliente AAD",
                tenantId: "Tenant ID",
                url: "URL da instância AAD",
                redirectUri: "URL de retorno da autenticação AAD",
                provider: "Provider"
            }
        },
        tooltip: {
            enableAAD: "SSO (Single Sign-On) permite acessar diversos sistemas com uma única autenticação. AAD (Azure Active Directory) é a plataforma da Microsoft que gerencia essa autenticação, oferecendo mais segurança e praticidade."
        },
        placeholder: {
            AAD: {
                id: "Informe o ID do Cliente AAD",
                tenantId: "Informe o Tenant ID",
                url: "Informe a URL da instância AAD",
                redirectUri: "Informe a URL de retorno da autenticação AAD",
                provider: "Informe o Provider"
            }
        }
    }
};
